<template>
  <div class="Index">
    <h5 class="header_title" style="background:#6a33db;">
      <!-- <img src="../assets/eth.png" width="20" /> -->
      <img src="../assets/logo.jpg" width="20" />
      <span style="color:#fff;">utrust-mining pool</span>
    </h5>

    <div class="share-bg">
      <h2 class="tl p-l-10 lg-title">
        <font style="vertical-align: inherit">
          <font style="vertical-align: inherit">Refer Friends.</font>
        </font>
      </h2>
      <h2 class="tl p-l-10">Earn Crypto Together.</h2>
      <p class="tl p-l-10">Earn up to 10% commission in DeFi utk</p>
      <div class="tl p-l-10 active-color"></div>
      <br />
      <div class="my-rebate-info flex align-center justify-between tc">
        <div>
          <div class="title">My Level</div>
          {{ teaminfo.vip }}
        </div>
        <div>
          <div class="title">Level 1</div>
          <div>{{ teaminfo.level1 }}</div>
        </div>
        <div>
          <div class="title">Level 2</div>
          <div>{{ teaminfo.level2 }}</div>
        </div>
        <div>
          <div class="title">Level 3</div>
          <div>{{ teaminfo.level3 }}</div>
        </div>
      </div>
    </div>

    <div class="content">
      <mt-navbar class="page-part" v-model="selected">
        <mt-tab-item id="tab-container1">Referral</mt-tab-item>
        <mt-tab-item id="tab-container2">Revenue</mt-tab-item>
        <mt-tab-item id="tab-container3">Record</mt-tab-item>
      </mt-navbar>
      <mt-tab-container v-model="selected">
        <mt-tab-container-item id="tab-container1">
          <h3>My referral link</h3>
          <mt-cell :title="share_address" class="copy">
            <mt-button type="primary" class="btns" ref="copy" :data-clipboard-text="share_address" @click="copy"
              id="copy_text">copy</mt-button>
          </mt-cell>
          <p>
            Share your invitation link. After your friends join the node through
            your link, they can get generous token rewards. For details of the
            event, please contact online customer service
          </p>
          <div class="earn-tip flex align-center justify-around">
            <div>
              <img src="../assets/share1.03ba2d8e.svg" width="60" alt="" />
              <h3>1. Get Link</h3>
            </div>
            <div>
              <img src="../assets/share2.6f4b6101.svg" width="60" alt="" />
              <h3>2. Invite Friends</h3>
            </div>
            <div>
              <img src="../assets/share3.3199a322.svg" width="60" alt="" />
              <h3>3. Earn Crypto</h3>
            </div>
          </div>
        </mt-tab-container-item>
        <mt-tab-container-item id="tab-container2">
          <ul class="revenue" v-infinite-scroll="loadMore" infinite-scroll-disabled="loading"
            infinite-scroll-distance="10">
            <li>
              <span>create_time</span>
              <span>amount</span>
              <span>from</span>
            </li>
            <li v-for="item in list">
              <span>{{ item.create_time }}</span>
              <span>{{ item.amount }}</span>
              <span>{{ item.from_uid }}</span>
            </li>
          </ul>
        </mt-tab-container-item>
        <mt-tab-container-item id="tab-container3">
          <div class="filters flex align-center justify-start">
            <span class="span_title">Level</span>
            <div>
              <span :class="level == 0 ? 'level' : ''" style="" @click="levelchange(0)">ALL</span><span
                :class="level == 1 ? 'level' : ''" @click="levelchange(1)">Level 1</span><span
                :class="level == 2 ? 'level' : ''" @click="levelchange(2)">Level 2</span><span
                :class="level == 3 ? 'level' : ''" @click="levelchange(3)">Level 3</span>
            </div>
          </div>
          <div>
            <ul class="revenue" v-infinite-scroll="loadMore" infinite-scroll-disabled="loading"
              infinite-scroll-distance="10">
              <li>
                <span>uid</span>
                <span>address</span>
                <span>create_time</span>
              </li>
              <li v-for="team in team.level" v-if="level == 0">
                <span>{{ team.uid }}</span>
                <span>{{ team.au_address.substr(0, 11) }}...</span>
                <span>{{ team.create_time }}</span>
              </li>
              <li v-for="team in team.level1" v-if="level == 1">
                <span>{{ team.uid }}</span>
                <span>{{ team.au_address.substr(0, 11) }}...</span>
                <span>{{ team.create_time }}</span>
              </li>
              <li v-for="team in team.level2" v-if="level == 2">
                <span>{{ team.uid }}</span>
                <span>{{ team.au_address.substr(0, 11) }}...</span>
                <span>{{ team.create_time }}</span>
              </li>
              <li v-for="team in team.level2" v-if="level == 3">
                <span>{{ team.uid }}</span>
                <span>{{ team.au_address.substr(0, 11) }}...</span>
                <span>{{ team.create_time }}</span>
              </li>
            </ul>
          </div>
        </mt-tab-container-item>
      </mt-tab-container>
    </div>
  </div>
</template>
<!--Add the following script at the bottom of the web page (before </body></html>)href('https://wa.me/+16722186853')-->
<script>
// @ is an alias to /src
import Clipboard from "clipboard";
import { team_list, team_get } from "./api/index";
export default {
  name: "share",
  data() {
    return {
      share_address: window.location.host,
      selected: "tab-container1",
      list: [1, 2, 3],
      level: 0,
      uid: 1,
      teaminfo: {
        vip: "vip1",
        level1: 0,
        level2: 0,
        level3: 0,
      },
      team: [],
    };
  },
  components: {},
  computed: {},
  created() {
    // console.log(this.$route.query);
    if (this.$route.query) {
      this.uid = this.$route.query.uid;
      let dl_id = this.$route.query.dl_id;
      if (this.uid == 0) {
        this.uid = this.GetUrlParam("uid");
      }
      if (dl_id == "") { dl_id = 0}
      this.share_address = this.share_address + "/#/?share=" + this.uid + "&reg_id=" + dl_id;
      this.team_list();
      this.team_get();
    }
  },
  mounted() {},
  methods: {
    team_get() {
      team_get({ uid: this.uid }).then((res) => {
        this.team = res.data;
        // console.log(this.team);
      });
    },
    team_list() {
      team_list({ uid: this.uid }).then((res) => {
        this.teaminfo = res.data;
        this.list = res.data.revenue;
      });
    },
    levelchange(e) {
      this.level = e;
    },
    loadMore() {
      this.loading = true;
      setTimeout(() => {
        let last = this.list[this.list.length - 1];
        for (let i = 1; i <= 10; i++) {
          this.list.push(last + i);
        }
        this.loading = false;
      }, 2500);
    },
    copy: function () {
      var _this = this;
      var clipboard = new Clipboard("#copy_text");

      clipboard.on("success", (e) => {
        // alert("dd");
        // 释放内存
        clipboard.destroy();
      });
      clipboard.on("error", (e) => {
        // 不支持复制
        Message({
          message: "该浏览器不支持自动复制",
          type: "warning",
        });
        // 释放内存
        clipboard.destroy();
      });
    },

    //paraName 等找参数的名称
    GetUrlParam(paraName) {
      var url = document.location.toString();
      var arrObj = url.split("?");

      if (arrObj.length > 1) {
        var arrPara = arrObj[1].split("&");
        var arr;

        for (var i = 0; i < arrPara.length; i++) {
          arr = arrPara[i].split("=");

          if (arr != null && arr[0] == paraName) {
            return arr[1];
          }
        }
        return "";
      } else {
        return "";
      }
    },
  },
};
</script>

<style scoped>
* {
  width: 100%;
  height: 100%;
  font-size: 13px;
}
.header_title {
  height: 2.3rem;
  /* background: rgb(22, 82, 240); */
}
.header_title img {
  width: 1.4rem;
  height: 1.4rem;
  position: relative;
  top: 0.5rem;
}
.header_title span {
  position: relative;
  top: 0.3rem;
  color: #000;
  font-weight: normal;
  font-size: 0.8rem;
}
.share-page .body-center {
  min-height: calc(100vh - 27.46667vw);
  background-color: var(--van-popup-background-color);
  overflow: hidden;
}
.share-bg {
  width: 100%;
  background: url(../assets/bg_share.dad9fa9e.png) no-repeat right 2.66667vw top;
  background-size: 35%;
}
h2 {
  display: block;
  font-size: 1.5em;
  margin-block-start: 0.83em;
  margin-block-end: 0.83em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  font-weight: bold;
}
p {
  display: block;
  margin-block-start: 1em;
  margin-block-end: 1em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
}
div {
  --van-tab-text-color: #333;
  --van-tab-active-text-color: #0052ff;
  --van-tabs-bottom-bar-color: #0052ff;
  --van-tabs-default-color: #0052ff;
  --van-tabs-nav-background-color: #f8f8f8;
  --van-dropdown-menu-background-color: #fff;
  --van-dropdown-menu-title-text-color: #333;
  --van-button-warning-background-color: #0052ff;
  --van-button-warning-border-color: #0052ff;
  --van-button-warning-color: #f8f8f8;
  --van-collapse-item-content-background-color: #fff;
  --van-cell-background-color: #f8f8f8;
  --van-cell-text-color: #333;
  --van-cell-active-color: #fff;
  --van-cell-border-color: #fff;
  --van-popup-background-color: #fff;
  --van-field-input-text-color: #959ba7;
  --van-dropdown-menu-title-font-size: 14px;
  --van-tabbar-background-color: #fff;
  --van-tabbar-item-active-background-color: #f8f8f8;
  --van-tabbar-item-active-color: #0052ff;
  --van-nav-bar-background-color: #f8f8f8;
  --van-nav-bar-icon-color: #0052ff;
  --van-nav-bar-title-text-color: #0052ff;
  --van-count-down-text-color: #0052ff;
  --van-count-down-font-size: 16px;
  --van-cus-border-color: #efefef;
  --van-cus-background-color: #f8f8f8;
  --van-cus-front-color: #fff;
  --van-cus-assets-bg-color1: rgba(0, 0, 0, 0.05);
  --van-cus-assets-bg-color2: rgba(0, 0, 0, 0.05);
  --van-cus-img-bg-color: hsla(0, 0%, 100%, 0.2);
  --van-cus-text-color: #333;
  --van-cus-link-light-color: rgba(0, 82, 255, 0.05);
}
.tl {
  text-align: left;
}

.p-l-10 {
  padding-left: 2.66667vw;
}

.share-bg h2,
h3 {
  color: #0052ff;
  margin: 6vw 0;
  width: 95%;
}
.share-bg p {
  color: #959ba7;
  text-align: left;
  margin: 8vw 0;
  width: 95%;
}

.share-bg .my-rebate-info {
  background-color: var(--van-cus-link-light-color);
  /* margin: 5.33333vw 2.66667vw 2.66667vw 2.66667vw; */
  border-radius: 2.13333vw;
  padding: 2.66667vw 0;
  margin: auto;
  width: 98%;
}
.justify-between {
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  justify-content: space-between;
}
.align-center {
  -webkit-box-align: center;
  -webkit-align-items: center;
  align-items: center;
}
.flex {
  display: -webkit-box;
  display: -webkit-flex;
  display: flex;
}
.justify-around {
  -webkit-justify-content: space-around;
  justify-content: space-around;
}
.justify-start {
  -webkit-box-pack: start;
  -webkit-justify-content: flex-start;
  justify-content: flex-start;
}
.share-bg .my-rebate-info .title {
  margin: 1.33333vw 0;
}

.content {
  margin-top: 5vw;
}
.mint-navbar {
  font-size: 1.5rem;
}
.content .mint-tab-container h3 {
  font-size: 1rem;
  margin: 0;
  margin-top: 6vw;
}
.copy {
  background: #f8f8f8;
  padding-left: 5vw;
  width: 95%;
  margin: 3vw auto;
  color: #959ba7;
}

.van-cell-group {
  background-color: var(--van-cell-group-background-color);
}
.btns {
  width: 60px;
  padding: 10px 0px;
}
.content .mint-tab-container p {
  width: 95%;
  margin: 3vw auto;
  color: #959ba7;
  text-align: left;
}
.content .earn-tip {
  margin-top: 10vw;
}
.content .earn-tip div {
  width: 18%;
}
.content .earn-tip div h3 {
  font-size: 12px;
}
.revenue {
  margin-top: 5vw;
  width: 100%;
}
.revenue li {
  height: 36px;
  line-height: 36px;
  display: flex;
}

.filters {
  width: 100%;
  margin: 4vw 0;
  padding: 0 4vw;
}
.filters .span_title {
  display: inline-block;
  width: 15%;
}
.filters div {
  text-align: left;
}
.filters div span {
  display: inline-block;
  width: 12%;
  text-align: center;
  margin-right: 3vw;
}
.filters div span.level {
  background: rgb(0, 82, 255);
  color: rgb(255, 255, 255);
}
</style>
